import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Freunde} from "../../model/freunde";

@Component({
  selector: 'app-add-connection',
  templateUrl: './add-connection.component.html',
  styleUrl: './add-connection.component.scss'
})
export class AddConnectionComponent {
  @Input() selectedFriend: Freunde | null = null;
  @Input() allFriends: Array<Freunde> = [];
  @Output() backToMain = new EventEmitter<unknown>();

  selectedConnectionFriend: Freunde | null = null;
  description: string = '';

  saveConnection(): void {
    if (this.selectedConnectionFriend && this.description) {

      // Zurücksetzen der Felder
      this.selectedConnectionFriend = null;
      this.description = '';
    } else {
      alert("Bitte wähle eine Person und gib eine Beschreibung ein.");
    }
  }

  cancel(): void {

  }
}
