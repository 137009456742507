import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Freunde} from "../model/freunde";
import {FreundeService} from "../service/freunde.service";
import {environment} from "../../environments/environment";
import {MessageService} from 'primeng/api';
import {UploadService} from "../service/upload.service";

@Component({
  selector: 'app-freunde',
  templateUrl: './freunde.component.html',
  styleUrl: './freunde.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class FreundeComponent implements OnInit {
  freunde: Array<Freunde> = [];
  originalfreunde: Array<Freunde> = [];
  selectedFriend: Freunde | null = null;
  showFriends: boolean = true;
  showAddFriend: boolean = false;
  showAddConnection: boolean = false;
  showBackButton: boolean = false;
  showTableView: boolean = false;
  longPressTimeout: any = null;
  isLongPress = false;
  showContextMenuFlag = false; // Flag zum Anzeigen des Kontextmenüs
  contextMenuPosition = {x: 0, y: 0}; // Position des Menüs
  contextMenuFriend: Freunde | null = null;
  startX: number = 0; // Startposition X des Touch-Events
  startY: number = 0; // Startposition Y des Touch-Events
  swipeThreshold: number = 20; // Schwellenwert für das Wischen in Pixel

  isFilterApplied: boolean = false;
  filterInputText: string = '';

  constructor(private freundeService: FreundeService,
              private cdRef: ChangeDetectorRef,
              private messageService: MessageService,
              private uploadService: UploadService) {
  }

  ngOnInit() {
    this.getRelevantPersonen();
  }

  async onDrop(event: DragEvent, friend: any) {
    event.preventDefault();
    const file = event.dataTransfer?.files[0];

    if (file && file.type.startsWith('image/')) {
      // Foto hochladen und danach friend.photo mit dem Dateinamen aktualisieren
      this.uploadService.uploadPhoto(file).subscribe(
        response => {
          // Nur der Dateiname oder die URL (abhängig vom Server-Response) wird in friend.photo gespeichert
          const fileName = response.url.split('/').pop(); // Extrahiert nur den Dateinamen
          friend.photo = fileName || response.url;

          // Freund aktualisieren, nachdem das Foto hochgeladen und friend.photo gesetzt wurde
          this.freundeService.update(friend).subscribe(
            () => {
              this.refreshList();
            }
          );
        }
      );
    }
  }

  allowDrop(event: DragEvent) {
    event.preventDefault();
    event.dataTransfer!.dropEffect = "link";
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filterInputText = filterValue;
    this.freunde = this.originalfreunde.filter(friend =>
      friend.fullname.toLowerCase().includes(filterValue)
    );
  }

  applyFilterTop() {
    this.isFilterApplied = true;
    this.freunde = this.originalfreunde.filter(friend => {
      return friend.attributes.some(attribute =>
        attribute.attribut === 'Interesse' && Number(attribute.value) >= 8
      );
    });
  }

  applyAttributeFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    // Überprüfen, ob ein Filtertext eingegeben wurde
    if (filterValue) {
      this.freunde = this.originalfreunde.filter(friend => {
        return friend.attributes.some(attribute => {
          const attributName = attribute.attribut?.toLowerCase() || '';
          const attributValue = attribute.value?.toString().toLowerCase() || '';
          return attributName.includes(filterValue) || attributValue.includes(filterValue);
        });
      });
    } else {
      // Wenn kein Filtertext vorhanden ist, auf die Originaldaten zurücksetzen
      this.freunde = [...this.originalfreunde];
    }

    this.cdRef.detectChanges();
  }


  toggleView() {
    this.showTableView = !this.showTableView;
  }

  selectFriend(friend: Freunde): void {
    this.closeContextMenu();
    this.selectedFriend = friend;
  }

  getAlle() {
    this.isFilterApplied = false;
    this.freundeService.getAlleRelevanten().subscribe(response => {
      response.sort((a, b) => {
        const nameA = `${a.vorname} ${a.nachname}`.toLowerCase();
        const nameB = `${b.vorname} ${b.nachname}`.toLowerCase();
        return nameA.localeCompare(nameB);
      });

      // Setze die vollständige URL für jedes Bild
      this.freunde = response.map(friend => {
        return {
          ...friend,
          photo: `${environment.downloadUrl}thumbnails/${friend.photo}`
        };
      });
      this.originalfreunde = this.freunde;
      this.cdRef.detectChanges();
    });
  }

  refreshList() {
    this.getRelevantPersonen();
  }

  addFriend() {
    this.showFriends = false;
    this.showAddFriend = true;
    this.showBackButton = true;
    this.selectedFriend = null;
  }

  editFriend(friend: any) {
    this.closeContextMenu();
    this.selectedFriend = friend;
    this.showFriends = false;
    this.showAddFriend = true;
    this.showBackButton = true;
    this.getAlle();
    this.cdRef.detectChanges();
  }

  deleteFriend(friend: any) {
    this.closeContextMenu();
    this.freundeService.deleteById(friend.id).subscribe(() => {
      this.cdRef.detectChanges();
      this.messageService.add({
        severity: 'info',
        summary: 'Profil gelöscht',
        detail: 'Profil ' + this.selectedFriend?.fullname + ' wurde entfernt.'
      });
    })
  }

  onTouchEnd(event: TouchEvent, friend: Freunde): void {
    clearTimeout(this.longPressTimeout); // Timeout abbrechen

    // Berechnet die Bewegungsdistanz
    const endX = event.changedTouches[0].clientX;
    const endY = event.changedTouches[0].clientY;
    const diffX = Math.abs(endX - this.startX);
    const diffY = Math.abs(endY - this.startY);

    // Überprüft, ob die Bewegung als Wischen zu betrachten ist
    if (diffX > this.swipeThreshold || diffY > this.swipeThreshold) {
      // Wenn die Bewegung größer als der Schwellenwert ist, betrachte sie als Wischen und beende
      return;
    }

    // Wenn kein Long-Press und kein Wischen erkannt wurde, führen wir die Klick-Logik aus
    if (!this.isLongPress) {
      this.selectFriend(friend);
    }
  }

  onTouchStart(event: TouchEvent, friend: Freunde): void {
    // Speichert die Startposition des Touch-Events
    this.startX = event.touches[0].clientX;
    this.startY = event.touches[0].clientY;
    this.isLongPress = false;

    // Setzt einen Timeout für das lange Drücken
    this.longPressTimeout = setTimeout(() => {
      this.isLongPress = true;
      this.showContextMenu(friend, this.startX, this.startY);
    }, 500);
  }

  showContextMenu(friend: Freunde, x: number, y: number): void {
    const menuWidth = 150; // Beispielbreite des Menüs
    const menuHeight = 100; // Beispielhöhe des Menüs
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    // Menüposition anpassen, falls es außerhalb des Bildschirms ist
    const adjustedX = x + menuWidth > windowWidth ? windowWidth - menuWidth : x;
    const adjustedY = y + menuHeight > windowHeight ? windowHeight - menuHeight : y;

    this.contextMenuPosition = {x: adjustedX, y: adjustedY};
    this.showContextMenuFlag = true;
    this.contextMenuFriend = friend;
  }

  onRightClick(event: MouseEvent, friend: Freunde): void {
    event.preventDefault(); // Standard-Kontextmenü verhindern
    this.showContextMenu(friend, event.clientX, event.clientY); // Kontextmenü an der Mausposition anzeigen
  }

  closeContextMenu(): void {
    this.showContextMenuFlag = false;
    this.contextMenuFriend = null;
  }

  back() {
    debugger;
    this.showFriends = true;
    this.showAddFriend = false;
    this.showBackButton = false;

    if (this.filterInputText) {
      this.freunde = this.originalfreunde.filter(friend =>{
        friend.fullname.toLowerCase().includes(this.filterInputText);
        this.cdRef.detectChanges();});
    } else if (this.isFilterApplied) {
      this.applyFilterTop();
      this.cdRef.detectChanges();
    }
  }

  addConnection(friend: Freunde | null) {
    this.closeContextMenu();
    this.selectedFriend = friend;
    this.showFriends = false;
    this.showAddFriend = false;
    this.showAddConnection = true;
    this.showBackButton = true;
  }

  getRelevantPersonen() {
    this.freundeService.getAlleRelevanten().subscribe(response => {
      response.sort((a, b) => {
        const nameA = `${a.vorname} ${a.nachname}`.toLowerCase();
        const nameB = `${b.vorname} ${b.nachname}`.toLowerCase();
        return nameA.localeCompare(nameB);
      });

      // Setze die vollständige URL für jedes Bild
      this.freunde = response.map(friend => {
        return {
          ...friend,
          photo: `${environment.downloadUrl}thumbnails/${friend.photo}`
        };
      });
      this.originalfreunde = this.freunde;
    });
  }

}
