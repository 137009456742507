import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Events} from "../model/events";

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  baseUrl = `${environment.apiUrl}/api/events`;

  constructor(private http: HttpClient) { }

  addEvent(data: Events) {
    return this.http.post(`${this.baseUrl}/create`, data);
  }

  getEvents() {
    return this.http.get<Events[]>(`${this.baseUrl}/alle`);
  }

  getAlleByAssociatedId(id: string) {
    return this.http.get<Events[]>(`${this.baseUrl}/alleByAssociatedId/${id}`);
  }
}
