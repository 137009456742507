<p-toast></p-toast>

<div class="filter-bar">
  <!-- Filterbuttons -->
  <div class="filter-buttons">
    <p-button label="Sativa" (onClick)="filterByType('Sativa')" styleClass="p-button-outlined"></p-button>
    <p-button label="Indica" (onClick)="filterByType('Indica')" styleClass="p-button-outlined"></p-button>
    <p-button label="Hybrid" (onClick)="filterByType('Hybrid')" styleClass="p-button-outlined"></p-button>
    <p-button label="Alle" (onClick)="resetFilter()" styleClass="p-button-outlined p-button-secondary"></p-button>
  </div>

  <!-- Suchfeld -->
  <div class="filter-search">
    <input type="text" [(ngModel)]="searchQuery" (keyup)="onSearch($event)"
           placeholder="Wie geht es dir..." class="search-input"/>
  </div>

  <!-- Totalinformationen -->
  <div class="total-info">
    <p><strong>Gesamt Spliffs:</strong> {{ getTotalCount() }}</p>
    <p><strong>Gesamt Gramm:</strong> {{ getTotalGramm() }}</p>
  </div>
</div>

<!-- Menübereich -->
<div class="menu-grid">
  <div class="menu-card" *ngFor="let item of filteredMenuList">
    <div class="menu-card-header">
      <div class="menu-card-image-container">
        <img [src]="getImageForSorte(item.sorte)" alt="{{ item.sorte }}" class="menu-card-image">
        <span class="spliff-count">{{ item.count }}</span>
        <span class="gramm-count">{{ item.gramm }}g</span>
        <span class="sativa">{{ item.sativa }}%</span>
        <span class="indica">{{ item.indica }}%</span>
      </div>
    </div>
    <div class="menu-card-content">
      <div class="menu-card-title-container">
        <h4 class="menu-card-title">
          <i class="pi pi-leaf"></i> {{ item.sorte }}
        </h4>
      </div>
      <p>{{ item.geschmack }}</p>
      <p>{{ item.wirkung }}</p>
      <div class="menu-card-actions">
        <p-button
          (onClick)="decreaseCount(item)"
          icon="pi pi-minus"
          styleClass="p-button-rounded p-button-text">
        </p-button>
        <input type="number" [(ngModel)]="increaseValue" min="1" class="card-input">
        <p-button
          (onClick)="op.toggle($event)"
          icon="pi pi-plus"
          styleClass="p-button-rounded p-button-text">
        </p-button>
        <p-overlayPanel #op>
          <div class="flex flex-column gap-3 w-20rem">
            <div>
              <span class="font-medium text-900 block mb-2">Wie viele Gramm sollen abgezogen werden?</span>
              <input type="text" [(ngModel)]="grammValueInput" (input)="onGrammInput($event)" placeholder="0,0"
                     class="w-full">
            </div>
            <div class="modal-actions">
              <p-button label="Bestätigen" icon="pi pi-check" (onClick)="confirmGrammUpdate(item, op)"
                        class="p-button-success"></p-button>
              <p-button label="Abbrechen" icon="pi pi-times" (onClick)="op.hide()"
                        class="p-button-secondary"></p-button>
            </div>
          </div>
        </p-overlayPanel>
      </div>
    </div>
  </div>
</div>

<div class="history-section">
  <h1>Verbrauch</h1>
  <table>
    <thead>
    <tr>
      <th>Sorte</th>
      <th>Datum</th>
      <th>Spliffs</th>
      <th>g</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let entry of history">
      <td>{{ entry.sorte }}</td>
      <td>{{ entry.timestamp | date: 'short' }}</td>
      <td>{{ entry.count }}</td>
      <td>{{ entry.gramm }}</td>
    </tr>
    </tbody>
  </table>
</div>
