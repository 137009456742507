import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Freunde} from "../model/freunde";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FreundeService {
  baseUrl = `${environment.apiUrl}/api/person`;

  constructor(private http: HttpClient) { }

  addFreund(friend: Freunde): Observable<Freunde> {
    return this.http.post<Freunde>(`${this.baseUrl}/create`, friend);
  }

  getById(id: string) {
    return this.http.get<Freunde>(`${this.baseUrl}/get/${id}`);
  }

  getAlle() {
    return this.http.get<Freunde[]>(`${this.baseUrl}/alle`);
  }

  getAlleRelevanten() {
    return this.http.get<Freunde[]>(`${this.baseUrl}/alleRelevant`);
  }

  getKinder() {
    return this.http.get<Freunde[]>(`${this.baseUrl}/kinder`);
  }

  update(editFriend: Freunde) {
    return this.http.put(`${this.baseUrl}/update`, editFriend);
  }

  delete(selectedFriend: Freunde) {
    return this.http.delete(`${this.baseUrl}/delete`, { body: selectedFriend });
  }

  deleteById(id: string) {
    return this.http.delete(`${this.baseUrl}/delete/${id}`);
  }


}
