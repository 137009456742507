import {Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})
export class ItemListComponent implements OnInit, OnChanges{
  @Input() items: any[] = []; // Liste der Artikel
  @Input() title: string = ''; // Titel der Kategorie
  @Output() addItem = new EventEmitter<void>(); // Event für das Hinzufügen eines Artikels
  @Output() editItem = new EventEmitter<void>();

  filteredItems: any[] = []; // Gefilterte Liste
  searchQuery: string = ''; // Suchfeld-Eingabe

  ngOnInit() {
    this.filteredItems = [...this.items];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['items']) {
      this.filteredItems = [...this.items]; // Aktualisiere die gefilterte Liste
      this.onSearch(); // Falls eine Suchanfrage aktiv ist, wird die Liste erneut gefiltert
    }
  }

  onSearch() {
    this.filteredItems = this.items.filter(item =>
      item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  onAddItem() {
    this.addItem.emit(); // Event für die Neuanlage eines Artikels auslösen
  }

  editArtikel(item: any) {
    this.editItem.emit(item);
  }
}
