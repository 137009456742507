import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Todo} from "../model/todo";
import {environment} from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class TodoService {

  baseUrl = `${environment.apiUrl}/api/todos`;

  constructor(private http: HttpClient) {
  }

  getTodoList() {
    return this.http.get<Todo[]>(`${this.baseUrl}/allTodos`);
  }

  addTodo(data: Todo) {
    return this.http.post(`${this.baseUrl}/create`, data);
  }

  updateTodo(data: Todo) {
    return this.http.put(`${this.baseUrl}/update`, data);
  }

  completeTodo(data: Todo) {
    return this.http.put(`${this.baseUrl}/complete`, data);
  }

  deleteTodo(id: Todo['id']) {
    return this.http.delete(`${this.baseUrl}/delete/${id}`);
  }

  increasePrio(id: Todo['id']) {
    return this.http.post(`${this.baseUrl}/increasePrio/${id}`, {});
  }

  decreasePrio(id: Todo['id']) {
    return this.http.post(`${this.baseUrl}/decreasePrio/${id}`, {});
  }
}
