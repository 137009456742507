// src/app/app.routes.ts

import {Routes} from '@angular/router';
import {TodoComponent} from './todo/todo.component';
import {HomeComponent} from './home/home.component';
import {KalenderComponent} from "./kalender/kalender.component";
import {FreundeComponent} from "./freunde/freunde.component";
import {DartsComponent} from "./dart/darts/darts.component";
import {DartGameAroundTheClockComponent} from "./dart/dart-game-around-the-clock/dart-game-around-the-clock.component";
import {TimelineComponent} from "./timeline/timeline.component";
import {MenuComponent} from "./weed-menu/weed-menu.component";
import {AuthGuard} from "./security/auth.guard";
import {InventarComponent} from "./inventar/inventar.component";
import {MapComponent} from "./map/map.component";
import {AddFriendComponent} from "./freunde/add-friend/add-friend.component";


export const APP_ROUTES: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'todo-list', component: TodoComponent, canActivate: [AuthGuard], data: { role: ['user','admin', 'root'] } },
  { path: 'kalender', component: KalenderComponent, canActivate: [AuthGuard], data: { role: ['user', 'admin','root'] } },
  { path: 'freunde', component: FreundeComponent, canActivate: [AuthGuard], data: { role: ['root'] } },
  { path: 'freunde/:id', component: AddFriendComponent, canActivate: [AuthGuard], data: { role: ['root'] } },
  { path: 'darts', component: DartsComponent, canActivate: [AuthGuard], data: { role: ['user', 'admin', 'root'] } },
  { path: 'timeline', component: TimelineComponent, canActivate: [AuthGuard], data: { role: ['user','admin', 'root'] } },
  { path: 'dart-game', component: DartGameAroundTheClockComponent, canActivate: [AuthGuard], data: { role: ['user','admin', 'root'] } },
  { path: 'weed-menu', component: MenuComponent, canActivate: [AuthGuard], data: { role: ['admin', 'root'] } },
  { path: 'inventar', component: InventarComponent, canActivate: [AuthGuard], data: { role: ['root'] } },
  { path: 'maps', component: MapComponent, canActivate: [AuthGuard], data: { role: ['user','admin', 'root'] } },
];
