<nav class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-toggle">
        <button type="button" class="navbar-toggler" (click)="sidebarToggle()">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="">MyLife</a>
      <p-button
        (onClick)="resetWindow()"
        [ngStyle]="{ 'opacity': '0', 'width': '30px', 'height': '30px', 'padding': '0', 'border': 'none', 'background': 'transparent' }">
      </p-button>
    </div>
    <button class="navbar-toggler" type="button" (click)="sidebarToggle()"
            [attr.aria-expanded]="!sidebarVisible" aria-controls="collapseExample">
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="collapseExample">
      <!-- Home Button -->
      <ul class="navbar-nav">
        <!-- Search Form -->
        <form class="ml-3">
          <div class="input-group no-border">
            <input type="text" value="" class="form-control" placeholder="Search...">
            <div class="input-group-append">
              <div class="input-group-text">
                <i class="nc-icon nc-zoom-split"></i>
              </div>
            </div>
          </div>
        </form>

        <!-- Notifications, Stats, and Account Settings -->
        <li class="nav-item btn-rotate" ngbDropdown placement="bottom-left">
          <a class="nav-link" ngbDropdownToggle id="navbarDropdownMenuLink">
            <i class="nc-icon nc-bell-55"></i>
            <p>
              <span class="d-lg-none d-md-block">Notifications</span>
            </p>
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarDropdownMenuLink" class="dropdown-menu dropdown-menu-right">
            <a ngbDropdownItem href="javascript:void(0)">Action</a>
            <a ngbDropdownItem href="javascript:void(0)">Another action</a>
            <a ngbDropdownItem href="javascript:void(0)">Something else here</a>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link btn-rotate" href="javascript:void(0)">
            <i class="nc-icon nc-settings-gear-65"></i>
            <p>
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
