<meta name="viewport" content="width=device-width, initial-scale=1.0">
<p-toast position="bottom-right"></p-toast>
<p-dialog header="Bestätigung" [(visible)]="displayConfirmation" [modal]="true" [closable]="false">
  <p>Möchten Sie diese Aufgabe wirklich löschen?</p>
  <p-footer>
    <button type="button" pButton label="Ja" (click)="confirmDelete(selectedTodoId)"></button>
    <button type="button" pButton label="Nein" icon="pi pi-times" (click)="displayConfirmation = false"></button>
  </p-footer>
</p-dialog>

<!-- TODO-Liste -->
<div class="flex flex-column align-items-left justify-content-center">
  <div class="flex align-items-center">
    <div>
      <input type="text" pInputText [(ngModel)]="todo" (keydown.enter)="addTodo()"
             placeholder="Neue Aufgabe eintragen"
             #todoTask="ngModel" required class="todo-inputbox">
    </div>
    <p-button icon="pi pi-check" class="ml-1" [disabled]="!!todoTask.invalid" (onClick)="addTodo()"
              pTooltip="Aufgabe hinzufügen"></p-button>
  </div>
  <p-table *ngIf="todos && todos.length > 0" [value]="getActiveTodos()">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 5%;" [pSortableColumn]="'prio'">Prio
          <p-sortIcon field="prio"></p-sortIcon>
        </th>
        <th style="width: 60%;" [pSortableColumn]="'task'">Aufgabe
          <p-sortIcon field="task"></p-sortIcon>
        </th>
        <th style="width: 30%;" [pSortableColumn]="'category'">Kategorie
          <p-sortIcon field="category"></p-sortIcon>
        </th>
        <th style="width: 5%; max-width: 70px;">Aktionen</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-todo>
      <tr>
        <td>
          <div style="display: flex; align-items: center; font-size: 0.8rem; justify-content: center;">
            <!-- Anzeige der Priorität -->
            <span>{{ todo.prio }}</span>

            <button (click)="increasePriority(todo)" style="border: none; background: none; cursor: pointer;">
              <i class="fas fa-arrow-up" style="color: green;"></i>
            </button>

            <button (click)="decreasePriority(todo)" style="border: none; background: none; cursor: pointer;">
              <i class="fas fa-arrow-down" style="color: red;"></i>
            </button>
          </div>
        </td>
        <td>
          <div style="font-size: 0.8rem;">
            {{ todo.task }}
          </div>
        </td>
        <td>
          <p-dropdown [options]="categories" [(ngModel)]="todo.category" (onBlur)="updateTodo(todo,todo.category)"
                      (keydown.enter)="updateTodo(todo, todo.category)" optionLabel="label"
                      [editable]="true" appendTo="body" placeholder="Wähle eine Kategorie"></p-dropdown>
        </td>
        <td>
          <div style="display: flex; gap: 10px; align-items: center; margin-left: 7px; margin-right: 7px;">
            <button (click)="completeTodo($event, todo)" pTooltip="Aufgabe erledigen" class="button-spacing" style="background-color: #4ae94a;">
              <i class="fas fa-check"></i>
            </button>
            <button (click)="deleteTodo(todo?.id)" pTooltip="Aufgabe löschen" class="button-spacing" style="background-color: #f11010;">
              <i class="pi pi-trash"></i>
            </button>
          </div>
        </td>

      </tr>
    </ng-template>
  </p-table>
</div>

<hr/>

<!-- DONE-Liste -->
<div class="flex flex-column align-items-left justify-content-center">
  <p-accordion styleClass="my-accordion">
    <p-accordionTab header="Liste der Erledigten anzeigen" [selected]="false" style="background-color: green;">
      <p-table *ngIf="completedTodos.length > 0" [value]="completedTodos">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 50%;">Aufgabe</th>
            <th style="width: 15%;">Kategorie</th>
            <th style="width: 15%;">Erledigt am</th>
            <th style="width: 15%;">Aktionen</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-done>
          <tr>
            <td>{{ done.task }}</td>
            <td>{{ done.category }}</td>
            <td>{{ done.completed ? 'Erledigt' : '' }}</td>
            <td>
              <div style="display: flex; gap: 10px; align-items: center;">
                <p-button icon="pi pi-replay" severity="info" (onClick)="reactivateTodo(done)" class="button-spacing"
                        pTooltip="Aufgabe aktivieren"></p-button>
                <p-button icon="pi pi-trash" severity="danger" pTooltip="Aufgabe löschen"
                        (onClick)="deleteTodo(done?.id)"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-accordionTab>
  </p-accordion>
</div>
