import {NgModule} from '@angular/core';
import {BrowserModule, provideClientHydration} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ListboxModule} from 'primeng/listbox'; // Importiere das ListboxModul
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CardModule} from 'primeng/card';
import {TableModule} from 'primeng/table';
import {CheckboxModule} from 'primeng/checkbox';
import {FormsModule} from '@angular/forms'
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {HttpClientModule} from '@angular/common/http';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from "primeng/menubar";
import {SidebarComponent} from "./sidebar/sidebar.component";
import {NavbarComponent} from "./navbar/navbar.component";
import {TodoComponent} from './todo/todo.component';
import {HomeComponent} from './home/home.component';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import {DialogModule} from 'primeng/dialog';
import {AccordionModule} from 'primeng/accordion';
import {DropdownModule} from 'primeng/dropdown';
import {KalenderComponent} from './kalender/kalender.component';
import {CalendarModule} from "primeng/calendar";
import {ContextMenuModule} from 'primeng/contextmenu';
import {FreundeComponent} from './freunde/freunde.component';
import {OrderListModule} from "primeng/orderlist";
import {DragDropModule} from '@angular/cdk/drag-drop';
import {StepperModule} from 'primeng/stepper';
import {DartsComponent} from './dart/darts/darts.component';
import {DartGameAroundTheClockComponent} from './dart/dart-game-around-the-clock/dart-game-around-the-clock.component';
import {DartResultComponent} from './dart/dart-result/dart-result.component';
import {DartRankingComponent} from './dart/dart-ranking/dart-ranking.component';
import {DartShowGamesComponent} from "./dart/dart-show-games/dart-show-games.component";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {ChipsModule} from "primeng/chips";
import { AddEventComponent } from './kalender/add-event/add-event.component';
import {NgOptimizedImage} from "@angular/common";
import {SelectButtonModule} from "primeng/selectbutton";
import {AutoCompleteModule} from "primeng/autocomplete";
import { AddFriendComponent } from './freunde/add-friend/add-friend.component';
import {ImageModule} from "primeng/image";
import {FileUploadModule} from "primeng/fileupload";
import {RatingModule} from "primeng/rating";
import {TimelineComponent} from './timeline/timeline.component';
import {TimelineModule} from "primeng/timeline";
import {OrganizationChartModule} from "primeng/organizationchart";
import { AddConnectionComponent } from './freunde/add-connection/add-connection.component';
import { MenuComponent } from './weed-menu/weed-menu.component';
import {InputNumberModule} from "primeng/inputnumber";
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { InventarComponent } from './inventar/inventar.component'
import {TabMenuModule} from "primeng/tabmenu";
import { ItemListComponent } from './inventar/item-list/item-list.component';
import { ItemAddTechnikComponent } from './inventar/item-add-technik/item-add-technik.component';
import { ItemAddKlamottenComponent } from './inventar/item-add-klamotten/item-add-klamotten.component';
import { ItemAddModellautosComponent } from './inventar/item-add-modellautos/item-add-modellautos.component';
import {RadioButtonModule} from "primeng/radiobutton";
import {FloatLabelModule}   from "primeng/floatlabel";
import { DynamicFormFieldComponent } from './freunde/dynamic-form-field/dynamic-form-field.component';
import { MapComponent } from './map/map.component';

registerLocaleData(localeDe, 'de-DE');

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    NavbarComponent,
    TodoComponent,
    HomeComponent,
    KalenderComponent,
    FreundeComponent,
    DartsComponent,
    DartGameAroundTheClockComponent,
    DartResultComponent,
    DartRankingComponent,
    DartShowGamesComponent,
    AddEventComponent,
    AddFriendComponent,
    TimelineComponent,
    AddConnectionComponent,
    MenuComponent,
    InventarComponent,
    ItemListComponent,
    ItemAddTechnikComponent,
    ItemAddKlamottenComponent,
    ItemAddModellautosComponent,
    DynamicFormFieldComponent,
    MapComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CardModule,
    TableModule,
    CheckboxModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    HttpClientModule,
    MenuModule,
    MenubarModule,
    ToastModule,
    DialogModule,
    AccordionModule,
    DropdownModule,
    CalendarModule,
    ContextMenuModule,
    ListboxModule,
    OrderListModule,
    DragDropModule,
    StepperModule,
    OverlayPanelModule,
    InputGroupModule,
    InputGroupAddonModule,
    ChipsModule,
    NgOptimizedImage,
    SelectButtonModule,
    AutoCompleteModule,
    ImageModule,
    FileUploadModule,
    RatingModule,
    TimelineModule,
    OrganizationChartModule,
    InputNumberModule,
    TabMenuModule,
    RadioButtonModule,
    FloatLabelModule,
  ],
  providers: [provideClientHydration(), MessageService,{ provide: LOCALE_ID, useValue: 'de-DE' }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
