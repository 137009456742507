import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {FreundeService} from "../service/freunde.service";
import {Freunde} from "../model/freunde";
import {environment} from "../../environments/environment";
import {AttributeService} from "../service/attribute.service";
import {Attribute} from "../model/attribute";

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  private map: any;
  private apiUrl = 'https://nominatim.openstreetmap.org/search';

  constructor(private http: HttpClient,
              private friendsService: FreundeService,
              private attributeService: AttributeService) {}

  async ngOnInit(): Promise<void> {
    if (typeof window !== 'undefined') { // Sicherstellen, dass 'window' verfügbar ist
      const { default: L } = await import('leaflet');
      this.initMap(L);
    }
  }

  private initMap(L: any): void {
    this.map = L.map('map', {
      doubleClickZoom: false, // Doppelklick-Zoom deaktivieren
    }).setView([48.350788, 10.583063], 17);

    this.map.on('dblclick', (e: any) => {
      const { lat, lng } = e.latlng;
      this.addNewMarker(L, lat, lng);
      //this.fetchNearestAddress(L, lat, lng);
    });

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
    }).addTo(this.map);

    this.locateFriend(L);
  }

  private locateFriend(L: any): void {
    this.friendsService.getAlleRelevanten().subscribe((friends: Freunde[]) => {
      if (friends.length > 0) {
        friends.forEach((friend) => {
          // Adresse aus den Attributen extrahieren
          const coordinates = friend.attributes.find(attr => attr.attribut === 'coordinates')?.value;

          if (coordinates) {
            let coordinate = coordinates.split(',');
            this.addKnownMarker(L,+coordinate[0], +coordinate[1], friend);
          }
        });
      }
    });
  }

  private addNewMarker(L: any, lat: number, lng: number): void {
    this.friendsService.getAlleRelevanten().subscribe((friends: Freunde[]) => {
      const friendsList = friends.map(friend => friend.fullname);

      const popupContent = `
      <div>
        <h4>Neue Koordinaten zuordnen</h4>
        <div>
          <label for="friend-autocomplete">Person auswählen:</label>
          <input type="text" id="friend-autocomplete" placeholder="Person suchen..." list="friends-datalist" />
          <datalist id="friends-datalist">
            ${friendsList.map(friend => `<option value="${friend}"></option>`).join('')}
          </datalist>
        </div>
        <p>Koordinaten: ${lat.toFixed(5)}, ${lng.toFixed(5)}</p>
        <button id="save-coordinates-button">Speichern</button>
        <hr/>
        <h4>Neue Person hinzufügen</h4>
        <div>
          <label for="new-firstname">Vorname:</label>
          <input type="text" id="new-firstname" placeholder="Vorname" />
        </div>
        <div>
          <label for="new-lastname">Nachname:</label>
          <input type="text" id="new-lastname" placeholder="Nachname" />
        </div>
        <button id="create-person-button">Person erstellen</button>
      </div>
    `;

      const marker = L.marker([lat, lng], {
        icon: L.icon({
          iconUrl: '/assets/pinpoint.png',
          iconSize: [25, 25],
          iconAnchor: [25, 25],
        }),
      }).addTo(this.map);

      marker.bindPopup(popupContent).openPopup();

      setTimeout(() => {
        const saveButton = document.getElementById('save-coordinates-button');
        const autocompleteInput = document.getElementById('friend-autocomplete') as HTMLInputElement;
        const createButton = document.getElementById('create-person-button');
        const firstnameInput = document.getElementById('new-firstname') as HTMLInputElement;
        const lastnameInput = document.getElementById('new-lastname') as HTMLInputElement;

        // Speichern der Koordinaten für eine bestehende Person
        if (saveButton && autocompleteInput) {
          saveButton.addEventListener('click', () => {
            const selectedFriendName = autocompleteInput.value;
            const selectedFriend = friends.find(friend => friend.fullname === selectedFriendName);

            if (selectedFriend) {
              this.saveCoordinatesForFriend(selectedFriend, lat, lng);
              marker.closePopup();
              alert(`Koordinaten erfolgreich gespeichert für ${selectedFriendName}`);
            } else {
              alert('Bitte wählen Sie eine gültige Person aus der Liste aus.');
            }
          });
        }

        // Erstellen einer neuen Person
        if (createButton && firstnameInput && lastnameInput) {
          createButton.addEventListener('click', () => {
            const newFirstname = firstnameInput.value.trim();
            const newLastname = lastnameInput.value.trim();

            if (newFirstname && newLastname) {
              this.createNewPerson(newFirstname, newLastname, lat, lng);
              marker.closePopup();
              alert(`Neue Person "${newFirstname} ${newLastname}" erfolgreich hinzugefügt.`);
            } else {
              alert('Vorname und Nachname sind erforderlich, um eine neue Person hinzuzufügen.');
            }
          });
        }
      });
    });
  }

  private createNewPerson(firstname: string, lastname: string, lat: number, lng: number): void {
    const newPerson: Freunde = {
      id: '', // Die ID wird normalerweise von der API vergeben
      vorname: firstname,
      nachname: lastname,
      fullname: `${firstname} ${lastname}`,
      geburtstag: '', // Optional
      photo: '', // Optional
      geschlecht: '', // Optional
      attributes: [
        { id: '', idConnection: '', attribut: 'coordinates', value: `${lat.toFixed(5)},${lng.toFixed(5)}` },
      ],
      connection1: [],
      connection2: [],
      connections: [],
    };

    this.friendsService.addFreund(newPerson).subscribe(() => {
      console.log(`Neue Person erstellt: ${newPerson.fullname} mit Koordinaten ${lat}, ${lng}`);
    });
  }

  private saveCoordinatesForFriend(friend: Freunde, lat: number, lng: number): void {
    // Erstelle ein einziges Attribut für die Koordinaten
    const coordinatesAttribute: Attribute = {
      id: '',
      idConnection: friend.id,
      attribut: 'coordinates',
      value: `${lat.toFixed(5)},${lng.toFixed(5)}`, // Kombinierte Koordinaten als String
    };

    // Attribut speichern
    this.attributeService.addAttribute(coordinatesAttribute).subscribe(() => {
      console.log(`Koordinaten für ${friend.fullname} gespeichert: ${coordinatesAttribute.value}`);
    });
  }

  private addKnownMarker(L: any, lat: number, lng: number, friend?: Freunde): void {
    const marker = L.marker([lat, lng], {
      icon: L.icon({
        iconUrl: friend?.photo ? `${environment.downloadUrl}${friend.photo}` : '/assets/pinpoint.png',
        iconSize: [25, 25], // Größe des Icons
        iconAnchor: [25, 25], // Position des Icons relativ zum Marker
      }),
    }).addTo(this.map);

    const popupContent = friend
      ? `
      <div>
        <a href="/freunde/${friend.id}">
          ${friend.vorname} ${friend.nachname}
        </a>
        <p>Wohnort: ${friend.attributes.find(attr => attr.attribut === 'wohnort')?.value || 'Unbekannt'}</p>
        <p>Koordinaten: ${friend.attributes.find(attr => attr.attribut === 'coordinates')?.value || 'Unbekannt'}</p>
      </div>
    `
      : `
      <div>
        <h4>Unbekannter Ort</h4>
        <p>Koordinaten: ${lat.toFixed(5)}, ${lng.toFixed(5)}</p>
      </div>
    `;

    marker.bindPopup(popupContent);
  }

  private showAddressPopup(L: any, lat: number, lng: number, address: string): void {
    const marker = L.marker([lat, lng]).addTo(this.map);

    // HTML-Inhalt mit Angular-Datenbindung
    const popupContent = `
    <div>
      <label for="popup-address">Adresse:</label>
      <input type="text" id="popup-address" value="${address}" style="width: 250px;" />
      <button id="save-button">Speichern</button>
    </div>
  `;

    marker.bindPopup(popupContent).openPopup();

    // Button-Event mit Angular binden
    setTimeout(() => {
      const button = document.getElementById('save-button');
      const input = document.getElementById('popup-address') as HTMLInputElement;
      if (button && input) {
        button.addEventListener('click', () => {
          const updatedAddress = input.value;
          this.saveAddress(updatedAddress, lat, lng); // Angular-Methode aufrufen
        });
      }
    });
  }

  private saveAddress(address: string, lat: number, lng: number): void {
    console.log(`Neue Adresse: ${address} für Marker bei [${lat}, ${lng}]`);
    // Führe hier weitere Aktionen aus (z. B. API-Aufruf zum Speichern)
  }

  private fetchNearestAddress(L: any, lat: number, lng: number): void {
    const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`;

    this.http.get(url).subscribe((response: any) => {
      const address = response.display_name || 'Adresse konnte nicht ermittelt werden';
      console.log('Nächste Adresse:', address);
      this.showAddressPopup(L, lat, lng, address);
    });
  }

}
