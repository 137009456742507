<div class="add-connection">
  <h2>Verbindung für {{ selectedFriend?.fullname }} hinzufügen</h2>

  <!-- Auswahl der anderen Person für die Verbindung -->
  <div class="form-group">
    <label for="friendSelect">Mit Person verbinden:</label>
    <select id="friendSelect" [(ngModel)]="selectedConnectionFriend" class="form-control">
      <option *ngFor="let friend of allFriends" [ngValue]="friend" [disabled]="friend === selectedFriend">
        {{ friend.fullname }}
      </option>
    </select>
  </div>

  <!-- Eingabefeld für die Beschreibung der Verbindung -->
  <div class="form-group">
    <label for="description">Beschreibung:</label>
    <textarea id="description" [(ngModel)]="description" class="form-control" rows="3" placeholder="Gib eine Beschreibung für die Verbindung ein"></textarea>
  </div>

  <!-- Schaltflächen zum Speichern oder Abbrechen der Verbindung -->
  <div class="button-group">
    <button (click)="saveConnection()" class="btn btn-primary">Speichern</button>
    <button (click)="cancel()" class="btn btn-secondary">Abbrechen</button>
  </div>
</div>
