.global-actions {
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-bottom: 6px;
  margin-top: -15px;
  position: sticky; /* macht die Leiste "klebrig" */
  top: 0; /* bleibt oben am Bildschirm */
  z-index: 1000; /* bleibt über anderen Inhalten */
  background-color: transparent; /* Hintergrundfarbe, um Sichtbarkeit zu verbessern */

  .action-button, div .action-button {
    background-color: #ffffff;
    border: none;
    color: #000000;
    border-radius: 8px;
    padding: 5px 10px;
    font-size: 10px;
    transition: background-color 0.3s ease;

    .pi {
      font-size: 8px;
    }

    &:hover {
      background-color: #4363ef;
    }
  }
}

.friends-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 6px;
  justify-items: center;
}

.friend-card {
  position: relative;
  width: 100%;
  height: 120px;  // Höhe der Karte
  border-radius: 12px;
  cursor: pointer;
  color: white;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: box-shadow 0.3s ease;
  user-select: none;

  &:hover {
    box-shadow: 0 6px 20px rgb(18, 18, 200);
    transition: transform 0.2s ease;
  }

  &.selected {
    border: 2px solid #4caf50;
  }

  .friend-info {
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 0;
    text-align: center;
    padding: 5px;
    background: rgba(0, 0, 0, 0.6);  // Hintergrund-Overlay für Text
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .friend-name {
      font-weight: bold;
      color: #ffffff;
      font-size: 8px;
      display: inline-block;
    }
  }
}

.context-menu {
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.context-menu[style*="top"] {
  opacity: 1;
  transform: scale(1);
}

.context-menu button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  padding: 5px 10px;
  text-align: left;
  width: 100%;
}

.context-menu button:hover {
  background-color: #b7b7f3;
}

