import {Component, OnInit, ViewChild} from '@angular/core';
import {Todo} from "../model/todo";
import {MessageService} from "primeng/api";
import {TodoService} from "../service/todo.service";
import {animate, style, transition, trigger} from '@angular/animations';
import {EventsService} from "../service/events.service";

@Component({
  selector: 'app-todo',
  templateUrl: './todo.component.html',
  styleUrl: './todo.component.scss',
  animations: [
    trigger('fadeOut', [
      transition(':leave', [
        animate('0.5s ease-in', style({opacity: 0, transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})
export class TodoComponent implements OnInit {
  @ViewChild('todoTask') todoTask: any;
  todo = '';
  todos: Todo[] = [];
  displayConfirmation: boolean = false;
  selectedTodoId: Todo['id'] | null = null;
  isDone: boolean = false;
  categories: { label: string, value: string }[] = [];

  constructor(private todoService: TodoService,
              private messageService: MessageService,
              private eventService: EventsService) {
  }

  ngOnInit() {
    this.getList();
  }

  getActiveTodos(): Todo[] {
    return this.todos.filter(todo => !todo.completed);
  }

  getList() {
    this.todoService.getTodoList().subscribe(response => {
      this.todos = response.sort((a, b) => a.prio - b.prio);

      this.categories = Array.from(new Set(
        response
          .map(todo => todo.category) // Hole die Kategorien
          .filter(category => category !== '') // Ignoriere leere Kategorien
      ))
        .sort((a, b) => a.localeCompare(b)) // Sortiere alphabetisch
        .map(category => ({
          label: category,
          value: category
        }));
    });
  }

  updateTodo(todo: Todo, category?: any) {
    if (category && typeof category === 'object' && 'value' in category) {
      todo.category = category.value;
    }
    this.todoService.updateTodo(todo).subscribe(() => {
      this.getList();
    });
  }

  addTodo() {
    if (!this.todo.trim()) {
      this.messageService.add({severity: 'warn', summary: 'Warnung', detail: 'Kein leerer Wert zugelassen!'});
      return; // Vorzeitiger Abbruch, wenn das Feld leer ist
    }

    // Höchste existierende Priorität finden
    const maxPrio = this.todos.length > 0 ? Math.max(...this.todos.map(t => t.prio)) : 0;
    const newTodo: Todo = {
      id: null,
      completed: false,
      category: '',
      task: this.todo,
      prio: maxPrio + 1,
    };
    this.todoService.addTodo(newTodo).subscribe(() => {
      this.todoTask.reset();
      this.getList();
    });
  }

  completeTodo(e: MouseEvent, todo: Todo) {
    if (!todo.id) {
      console.error('Ungültige ID in completeTodo:', todo);
      return;
    }
    todo.completed = true; // Setze die Aufgabe auf erledigt
    this.todoService.completeTodo(todo).subscribe(() => {
      this.getList(); // Aktualisiere die Liste erst nach erfolgreicher Ausführung
      const newEvent = {
        id: null,
        startdate: new Date().toISOString(),
        enddate: '',
        kategorie: 'ToDo',
        beschreibung: 'Aufgabe: ' + todo.task + ' erledigt.',
        associatedId: todo.id || ""
      };
      this.eventService.addEvent(newEvent).subscribe();
      this.messageService.add({
        severity: 'success',
        summary: 'Erledigt',
        detail: 'Aufgabe erfolgreich abgeschlossen!',
        life: 1000
      });
    });
  }

  deleteTodo(id: Todo['id']) {
    if (!id) {
      console.error('Ungültige ID in deleteTodo:', id);
      return;
    }
    this.selectedTodoId = id;
    this.isDone = false;
    this.displayConfirmation = true;
  }

  confirmDelete(id: string | undefined | null) {
    if (id) {
      this.todoService.deleteTodo(id).subscribe(() => {
        this.todoService.decreasePrio(id).subscribe(() => {
          this.getList();
        });
      });
      this.displayConfirmation = false;
    }
  }

  get completedTodos(): Todo[] {
    return this.todos.filter(todo => todo.completed);
  }

  reactivateTodo(done: Todo) {
    done.completed = false;
    done.prio = 0;
    this.todoService.updateTodo(done).subscribe(() => {
      this.getList();
    });
  }

  increasePriority(todo: any) {
    this.todoService.increasePrio(todo.id).subscribe(() => this.getList());
  }

  decreasePriority(todo: any) {
    this.todoService.decreasePrio(todo.id).subscribe(() => this.getList());
  }

}
