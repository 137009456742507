<div *ngIf="showStartGameScreen">
  <p-stepper>
    <p-stepperPanel>
      <ng-template let-index="index" let-nextCallback="nextCallback" pTemplate="content">
        <div class="flex flex-column min-h-12rem mobile-h-auto">
          <div
            class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
            <p-dropdown
              [(ngModel)]="selectedPlayerCount"
              [options]="playerCountOptions"
              [showClear]="true"
              [style]="{width: '250px'}"
              placeholder="Anzahl Spieler auswählen">
            </p-dropdown>
          </div>
        </div>
        <div class="flex pt-4 justify-content-end">
          <p-button (onClick)="nextCallback.emit()" icon="pi pi-arrow-right" iconPos="right" label="Next"/>
        </div>
      </ng-template>
    </p-stepperPanel>

    <p-stepperPanel>
      <ng-template let-nextCallback="nextCallback"
                   let-prevCallback="prevCallback"
                   pTemplate="content">
        <div class="flex flex-column min-h-12rem mobile-h-auto">
          <div
            class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex flex-column justify-content-center align-items-center font-medium">
            <ng-container *ngFor="let i of [].constructor(selectedPlayerCount); let idx = index">
              <p-dropdown [(ngModel)]="selectedPlayers[idx]"
                          [options]="getFilteredPlayers(idx)"
                          [showClear]="true"
                          [style]="{width: '250px'}"
                          optionLabel="fullname"
                          scrollHeight="400px"
                          placeholder="Spieler {{ idx + 1 }} auswählen:">

                <!-- Template für die Dropdown-Items -->
                <ng-template let-player pTemplate="item">
                  <div class="flex align-items-center">
                    <img [src]="player.photo" alt="photo" class="mr-2" height="30" width="30"/>
                    <span>{{ player.fullname }}</span>
                  </div>
                </ng-template>
              </p-dropdown>
            </ng-container>
          </div>
        </div>
        <div class="flex pt-4 justify-content-between">
          <p-button (onClick)="prevCallback.emit()" icon="pi pi-arrow-left" label="Back"/>
          <p-button (onClick)="nextCallback.emit()" icon="pi pi-arrow-right" iconPos="right" label="Next"/>
        </div>
      </ng-template>
    </p-stepperPanel>

    <p-stepperPanel>
      <ng-template let-prevCallback="prevCallback" pTemplate="content">
        <div class="flex flex-column min-h-12rem mobile-h-auto">
          <div
            class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
            <div class="game-selection">
              <div *ngFor="let game of gameOptions; let i = index" class="game-option"
                   (click)="!isDisabled(i) && selectGame(game)">
                <img [src]="game.image" [alt]="game.label" class="game-image"
                     [style.height]="i === 1 || i === 2 ? '70px' : '100px'"
                     [style.width]="i === 1 || i === 2 ? 'auto' : '100px'"
                     [ngClass]="{'selected': selectedGameTyp === game.value, 'disabled': isDisabled(i)}"/>
                <p>{{ game.label }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex pt-4 justify-content-between">
          <p-button (onClick)="prevCallback.emit()" icon="pi pi-arrow-left" label="Back"></p-button>
        </div>
      </ng-template>
    </p-stepperPanel>
  </p-stepper>
</div>

<div *ngIf="showStartGameScreen" class="quick-start-box iphone-settings">
  <h2 class="quick-start-title">QuickLaunch</h2>
  <div class="quick-start-buttons">
    <div *ngIf="showStartGameScreen">
      <p-button (onClick)="startFixedGameIgorScreen()" icon="pi pi-arrow-right" iconPos="right"
                [style]="{'margin-bottom': '10px', 'margin-left':'10px', 'width': '300px'}"
                label="'Around the clock' für Igor Zerr"/>
      <p-button (onClick)="openRankingScreen()" icon="pi pi-arrow-right" iconPos="right"
                [style]="{'margin-bottom': '10px', 'margin-left':'10px', 'width': '300px'}"
                label="Öffne High Score Ranking"/>
      <p-button (onClick)="continueLastGame()" icon="pi pi-arrow-right" iconPos="right" [disabled]="!continueDarts?.id"
                [pTooltip]="getFormattedDate()" tooltipPosition="bottom"
                [style]="{'margin-bottom': '10px', 'margin-left':'10px', 'width': '300px'}" label="Spiel fortsetzen"/>
    </div>
  </div>
</div>

<div *ngIf="newDartGameId">
  <app-dart-game-around-the-clock
    [dartGameId]="newDartGameId"
    [players]="selectedPlayers"
    [continueDarts]="continueDarts"
    (backToMain)="onBackToMain()"
    (onGameEnd)="showResultScreen($event)">
  </app-dart-game-around-the-clock>
</div>

<div *ngIf="showResult">
  <app-dart-result
    [players]="selectedPlayers"
    [playerScores]="playerScores">
  </app-dart-result>
</div>

<div *ngIf="showRankingList">
  <app-dart-ranking
    (backToMain)="onBackToMain()"
    (showGameDetails)="showPreviousGameDetailsScreen($event)">
  </app-dart-ranking>
</div>

<div *ngIf="showGameDetails && selectedGameForDetails">
  <app-dart-show-games
    (backToMain)="openRankingScreen()"
    [selectedGame]="selectedGameForDetails"></app-dart-show-games>
</div>

