import {Component, OnInit} from '@angular/core';
import {MenuService} from '../service/menu.service';
import {Menu, menuToString} from '../model/menu';
import {EventsService} from "../service/events.service";
import {ChatGptService} from "../service/chatgpt.service";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-weed-menu',
  templateUrl: './weed-menu.component.html',
  styleUrls: ['./weed-menu.component.scss']
})
export class MenuComponent implements OnInit {
  menuList: Menu[] = [];
  history: Menu[] = [];
  increaseValue: number = 1; // Standardwert für die Erhöhung der Anzahl
  grammValue: number = 0.0;
  grammValueInput: string = "0";
  filteredMenuList: any[] = []; // Gefilterte Daten
  imageMap: { [key: string]: string } = {
    "G13 Haze": "/assets/g13.png",
    "Pineapple Chunk": "/assets/pineapple.png",
    "Zkittles OG Auto": "/assets/zkittles.png",
    "Glue Gelato Auto": "/assets/gluegelato.png",
    "Strawberry Cheesecake Auto": "/assets/strawberry.png",
    "Sour Diesel Auto": "/assets/sourDiesel.png",
    "Moby Dick Auto*": "/assets/mobydick.png",
    "Amnesia Lemon*": "/assets/amnesiaLemon.png",
    "Laughing Buddha Auto*": "/assets/laughingBuddha.png",
    "Mimosa X Orange Punch*": "/assets/mimosa.png",
    "Tangering Dream Auto": "/assets/tangerine.png",
    "Skywalker OG Auto": "/assets/skywalker.png",
  };
  searchQuery: string = ''

  constructor(private menuService: MenuService,
              private eventService: EventsService,
              private chatgpt: ChatGptService,
              private messageService: MessageService) {
  }

  onSearch(event: KeyboardEvent): void {
    if (event.key === 'Enter' && this.searchQuery.trim()) {
      this.chatgpt.sendMessage(this.getKIContext() + this.searchQuery).subscribe(
        (value:string) => {
          this.searchQuery="";
          this.messageService.add({
            severity: 'info',
            summary: 'ChatGPT Antwort',
            detail: value,
            life: 30000 // Toast bleibt 10 Sekunden sichtbar
          });
        }
      );
    }
  }

  getKIContext(): string{
    const query = this.menuList.filter(menu => menu.count>0).map(menu => menuToString(menu)).join('\n');
    return query + "Zeige mir nur 1 Sorte an und halte dich im Text kurz. Ermittle die beste Sorte für folgende Gefühlslage:";
  }

  getImageForSorte(sorte: string): string {
    return this.imageMap[sorte] || "/assets/images/default.png"; // Standardbild, falls Sorte nicht im Mapping ist
  }

  filterByType(type: string): void {
    this.filteredMenuList = this.menuList.filter(item => item.type === type);
  }

  resetFilter(): void {
    this.filteredMenuList = [...this.menuList];
  }

  onGrammInput(event: Event): void {
    const input = (event.target as HTMLInputElement).value;
    this.grammValue = parseFloat(input.replace(',', '.')); // Konvertiere Komma zu Punkt für Verarbeitung
  }

  confirmGrammUpdate(item: Menu, overlay: any): void {

    if (isNaN(this.grammValue) || this.grammValue <= 0) {
      alert("Bitte eine gültige Gramm-Menge eingeben!");
      return;
    }

    const newGramm = Number(item.gramm) - this.grammValue;

    if (newGramm < 0) {
      alert("Nicht genügend Gramm vorhanden!");
      return;
    }
    this.addNewEntry(item.sorte, Number(item.count) + Number(this.increaseValue), newGramm);
    overlay.hide(); // Schließt das OverlayPanel
  }

  ngOnInit(): void {
    this.loadMenuList();
    this.loadHistory();
  }

  loadMenuList(): void {
    this.menuService.getMenuList().subscribe((data: Menu[]) => {
      this.menuList = data
        .map((item) => {
          // Ergänze Geschmack und Wirkung basierend auf der Sorte
          const additionalInfo = this.getAdditionalInfoForStrain(item.sorte);
          return {
            ...item,
            geschmack: additionalInfo.geschmack,
            wirkung: additionalInfo.wirkung,
            type: additionalInfo.type,
            indica: additionalInfo.indica,
            sativa: additionalInfo.sativa,
          };
        })
        .sort((a, b) => a.sorte.localeCompare(b.sorte));
      this.filteredMenuList = [...this.menuList];
    });
  }

  getAdditionalInfoForStrain(sorte: string): {
    geschmack: string;
    wirkung: string;
    type: string;
    sativa: number;
    indica: number
  } {
    const strainInfo: {
      [key: string]: { geschmack: string; wirkung: string; type: string; sativa: number; indica: number }
    } = {
      'Amnesia Lemon*': {
        geschmack: 'Zitrus, Haze',
        wirkung: 'Energiegeladen, Kreativ',
        type: 'Sativa',
        sativa: 60,
        indica: 40,
      },
      'G13 Haze': {
        geschmack: 'Würzig, Kiefer',
        wirkung: 'Euphorisch, Entspannt',
        type: 'Sativa',
        sativa: 80,
        indica: 20,
      },
      'Glue Gelato Auto': {
        geschmack: 'Schokoladig, Süß',
        wirkung: 'Entspannend, Beruhigend',
        type: 'Hybrid',
        sativa: 50,
        indica: 50,
      },
      'Laughing Buddha Auto*': {
        geschmack: 'Süß, Würzig',
        wirkung: 'Euphorisch, Lächelnd',
        type: 'Sativa',
        sativa: 80,
        indica: 20,
      },
      'Mimosa X Orange Punch*': {
        geschmack: 'Zitrus, Fruchtig',
        wirkung: 'Glücklich, Energiegeladen',
        type: 'Indica',
        sativa: 65,
        indica: 35,
      },
      'Moby Dick Auto*': {
        geschmack: 'Würzig, Blumig',
        wirkung: 'Kreativ, Energiegeladen',
        type: 'Sativa',
        sativa: 70,
        indica: 30,
      },
      'Pineapple Chunk': {
        geschmack: 'Tropisch, Erdig',
        wirkung: 'Entspannend, Glücklich',
        type: 'Indica',
        sativa: 20,
        indica: 80,
      },
      'Skywalker OG Auto': {
        geschmack: 'Kiefer, Zitrus',
        wirkung: 'Beruhigend, Schlafend',
        type: 'Indica',
        sativa: 20,
        indica: 80,
      },
      'Sour Diesel Auto': {
        geschmack: 'Diesel, Würzig',
        wirkung: 'Energetisch, Klar',
        type: 'Sativa',
        sativa: 60,
        indica: 40,
      },
      'Strawberry Cheesecake Auto': {
        geschmack: 'Fruchtig, Süß',
        wirkung: 'Glücklich, Kreativ',
        type: 'Indica',
        sativa: 40,
        indica: 60,
      },
      'Tangering Dream Auto': {
        geschmack: 'Zitrus, Süß',
        wirkung: 'Fokussiert, Energiegeladen',
        type: 'Sativa',
        sativa: 70,
        indica: 30,
      },
      'Zkittles OG Auto': {
        geschmack: 'Süß, Tropisch',
        wirkung: 'Entspannt, Euphorisch',
        type: 'Hybrid',
        sativa: 50,
        indica: 50,
      },
    };

    // Fallback-Werte, falls die Sorte nicht in der Liste ist
    return (
      strainInfo[sorte] || {
        geschmack: 'Unbekannt',
        wirkung: 'Unbekannt',
        type: 'Unbekannt',
        sativa: 0,
        indica: 0,
      }
    );
  }

  decreaseCount(item: Menu): void {
    const newCount = item.count - 1;
    if (newCount >= 0) {
      this.addNewEntry(item.sorte, newCount, item.gramm);
    }
  }

  getTotalCount(): number {
    return this.menuList.reduce((total, item) => total + Number(item.count), 0);
  }

  getTotalGramm(): number {
    const total = this.menuList.reduce((total, item) => total + Number(item.gramm), 0);
    return parseFloat(total.toFixed(2));
  }

  addNewEntry(sorte: string, count: number, gramm: number): void {
    const newItem: Menu = {
      id: null,
      sorte: sorte,
      count: count,
      gramm: gramm,
      timestamp: new Date().toISOString(),
      geschmack: "",
      wirkung: "",
      type: "",
      sativa: 0,
      indica: 0,
    };
    this.menuService.addMenu(newItem).subscribe((savedItem) => {
      this.loadMenuList(); // Aktualisiere die Liste, um die neue Zeile anzuzeigen
      this.loadHistory();

      const newEvent = {
        id: null,
        startdate: new Date().toISOString(),
        enddate: '',
        kategorie: 'Menu',
        beschreibung: 'Sorte: ' + sorte,
        associatedId: savedItem.id || ""
      };
      this.eventService.addEvent(newEvent).subscribe();
    });
  }

  loadHistory(): void {
    this.menuService.getAll().subscribe((data: Menu[]) => {
      // Sortiere die Daten zuerst nach Sorte und dann nach Zeitstempel
      const sortedData = data.sort((a, b) => a.sorte.localeCompare(b.sorte) || a.timestamp.localeCompare(b.timestamp));

      // Filtere die Daten, um nur Reduzierungen darzustellen
      const filteredHistory = sortedData.filter((item, index, array) => {
        // Wenn es der erste Eintrag für die Sorte ist, überspringe ihn
        if (index === 0 || item.sorte !== array[index - 1].sorte) {
          return false;
        }

        // Vergleiche den aktuellen Eintrag mit dem vorherigen Eintrag der gleichen Sorte
        const previousItem = array[index - 1];
        return (Number(item.count) < Number(previousItem.count));
      });

      // Setze die gefilterte Liste in die `history`
      this.history = filteredHistory.sort((a, b) => a.timestamp.localeCompare(b.timestamp));
    });
  }

}
