import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../environments/environment";
import {Attribute} from "../model/attribute";

@Injectable({
  providedIn: 'root'
})
export class AttributeService {

  baseUrl = `${environment.apiUrl}/api/attribute`;

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<Attribute[]>(`${this.baseUrl}/all`);
  }

  addAttribute(data: Attribute) {
    return this.http.post<Attribute>(`${this.baseUrl}/create`, data);
  }

  deleteAttribute(id: Attribute['id']) {
    return this.http.delete<void>(`${this.baseUrl}/delete/${id}`);
  }
}
