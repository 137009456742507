<div class="result-page">
  <h1>
    Spielergebnis<br>
  </h1>

  <div class="result-summary">
    <div *ngFor="let player of players; let i = index" [ngClass]="{'winner': isWinner(i)}">
      <img [src]="player.photo" alt="{{ player.fullname }}" class="player-photo"/>
      <div class="player-details">
        <div class="player-name">
          <h2>{{ player.fullname }}</h2>
          <p>Punkte: <strong>{{ playerScores[i] }}</strong>
            <span *ngIf="!isWinner(i)">-{{ maxScore - playerScores[i] }}</span>
          </p>
        </div>
        <div class="cup-label" *ngIf="isWinner(i)">
          <span *ngIf="isWinner(i)">🏆</span>
          <span *ngIf="isWinner(i)">Gewinner!</span>
        </div>
      </div>
    </div>
  </div>

  <div class="actions">
    <p-button label="Neues Spiel starten" icon="pi pi-refresh" (onClick)="startNewGame()"></p-button>
  </div>
</div>
