import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InventarService} from "../../service/inventar.service";
import {Technik} from "../../model/technik";
import {environment} from "../../../environments/environment";
import {UploadService} from "../../service/upload.service";

@Component({
  selector: 'app-item-add-technik',
  templateUrl: './item-add-technik.component.html',
  styleUrl: './item-add-technik.component.scss'
})
export class ItemAddTechnikComponent implements OnInit {
  @Output() backToMain = new EventEmitter<unknown>();
  @Input() editItem: Technik | undefined;
  previewImageUrl: string | undefined = undefined;
  submitButton: string = "Hinzufügen";
  technik: Technik = {
    id: null,
    name: '',
    beschreibung: '',
    subject: '',
    kategorie: '',
    ort: '',
    menge: 1,
    hersteller: '',
    guerantee: 0,
    photo: '',
  };

  ngOnInit() {
    if (this.editItem) {
      this.technik = this.editItem;
      this.previewImageUrl = this.technik.photo?.replace("thumbnails/","");
      this.submitButton = "Updaten";
    } else {
      this.submitButton = "Hinzufügen";
    }
  }

  constructor(private inventarService: InventarService,
              private uploadService: UploadService) {
  }

  onFileSelected(event: any) {
    const file = event.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.previewImageUrl = reader.result as string;
      };
      reader.readAsDataURL(file);
      this.technik.photo = file.name;
      this.uploadService.uploadPhoto(file).subscribe(response => {
        if (this.technik) {
          const fileName = response.url.split('/').pop();
          this.technik.photo = fileName ? fileName : response.url;
          this.previewImageUrl = `${environment.downloadUrl}${this.technik.photo}`;
        }
      });
    }
  }

  onBasicUploadAuto(event: any) {
  }

  onSubmit() {
    if (this.technik) {
      if (!this.technik.photo && this.previewImageUrl) {
        this.technik.photo = this.previewImageUrl;
      }
      this.inventarService.createTechnik(this.technik).subscribe();
    }
    alert('Artikel erfolgreich hinzugefügt!');
    this.resetForm();
    this.backToMain.emit();
  }

  resetForm() {
    this.technik = {
      id: null,
      name: '',
      beschreibung: '',
      subject: '',
      kategorie: '',
      ort: '',
      menge: 1,
      hersteller: '',
      guerantee: 0,
      photo: '',
    };
    this.previewImageUrl = undefined;
  }

  onBack() {
    this.resetForm(); // Setze das Formular zurück
    this.backToMain.emit(); // Gehe zurück zur Hauptansicht
  }
}
