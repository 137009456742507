<div class="dart-game">
  <!-- Horizontale Anordnung von Spieler-Liste und Treffer Buttons -->
  <div class="top-section">
    <!-- Spielerliste mit aktueller Gesamtpunktzahl -->
    <div class="player-list">
      <ul>
        <li *ngFor="let player of players; let i = index" [ngClass]="{'current-player': i === playerOrder}">
          <img [src]="player.photo" alt="{{ player.fullname }}" class="player-photo">
          <p>{{ player.fullname }}</p>
          <div class="player-score">
            <strong> {{ playerScores[i] || 0 }} </strong>
          </div>
          <div class="player-highlight">
            <span *ngIf="showPopup[i]" class="score-popup tone-{{ scoreIncrease[i] }}">+{{ scoreIncrease[i] }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <!-- Aktuelle Wurfzahl -->
  <div class="current-throw-box">
    <h3 (click)="goBack()">
      Segment:
      <span class="segment-label">{{ segment }}</span>
    </h3>
  </div>

  <!-- Statistik -->
  <div class="statistic">
    <div class="statistic-content">
      <div class="average-quote">
        <p>Segment Trefferquote:</p>
        <div class="quote-value">{{ averageQuoteForCurrentPlayer }}</div>
      </div>
    </div>
  </div>

  <!-- Treffer Buttons -->
  <div class="throw-buttons">
    <div *ngFor="let i of throwOptions" class="arrow-container">
      <button (click)="registerHit(i)" class="dart-button">
        <img *ngFor="let j of [].constructor(i)" src="assets/dart2.png" alt="Dart Arrow" class="dart-arrow">
      </button>
    </div>
  </div>

</div>
