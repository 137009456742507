import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Dart} from "../model/darts";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DartsService {

  constructor(private http: HttpClient) {
  }
  baseUrl = `${environment.apiUrl}/api/dart`;

  gameToBeContinuedExists(){
    return this.http.get<Dart>(`${this.baseUrl}/continueGameExists`);
  }

  getDartGame(id: string) {
    return this.http.get<Dart>(`${this.baseUrl}/${id}`);
  }

  addDartGame(dart: Dart) {
    return this.http.post<string>(`${this.baseUrl}/create`, dart, {responseType: 'text' as 'json'});
  }

  updateDartThrow(dartId: string, playerId: string, index: number, value: number) {
    return this.http.post(`${this.baseUrl}/addThrowToGame`, null, {
      params: {
        dartId: dartId,
        playerId: playerId,
        index: index,
        value: value
      }, responseType: 'text'
    });
  }


  getAverageForPlayer(playerId: string, index: number) {
    return this.http.get(`${this.baseUrl}/averageThrow`, {
      params: {
        playerId: playerId,
        index: index,
      }, responseType: 'text'
    });
  }

  getRankedHighScore() {
    return this.http.get<Dart[]>(`${this.baseUrl}/rankedGames`);
  }
}
