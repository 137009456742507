import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-item-add-modellautos',
  templateUrl: './item-add-modellautos.component.html',
  styleUrl: './item-add-modellautos.component.scss'
})
export class ItemAddModellautosComponent {
  @Output() backToMain = new EventEmitter<unknown>();

}
