import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Freunde} from "../../model/freunde";
import {DartsService} from "../../service/darts.service";
import {Dart} from "../../model/darts";
import {EventsService} from "../../service/events.service";

@Component({
  selector: 'app-dart-game-around-the-clock',
  templateUrl: './dart-game-around-the-clock.component.html',
  styleUrls: ['./dart-game-around-the-clock.component.scss']
})
export class DartGameAroundTheClockComponent implements OnInit {
  @Input() players!: Freunde[];
  @Input() dartGameId: string = '';
  @Input() continueDarts: Dart | undefined;
  playerScores: number[] = []; // Array zum Speichern der aktuellen Punktzahl für jeden Spieler
  segment: string = "1"; // Aktueller Wurf
  throwOptions = [0, 1, 2, 3, 4, 5, 6]; // Optionen für die Treffer (0-6)
  playerOrder: number = 0;
  averageQuoteForCurrentPlayer: string = "0";

  showPopup: boolean[] = []; // Array, um anzuzeigen, bei welchem Spieler das Popup aktiv ist
  scoreIncrease: number[] = []; // Array, um den letzten Punkteanstieg anzuzeigen

  @Output() onGameEnd: EventEmitter<number[]> = new EventEmitter();

  constructor(private dartsService: DartsService,
              private eventService: EventsService) {
  }

  ngOnInit() {
    if (!this.players || this.players.length === 0) {
      console.error('Fehler: players ist nicht definiert oder leer.');
      return;
    }

    this.playerScores = Array(this.players.length).fill(0);

    if (this.players[0]?.id && this.segment) {
      this.dartsService.getAverageForPlayer(this.players[0].id, parseInt(this.segment, 10)).subscribe(
        response => {
          this.averageQuoteForCurrentPlayer = parseFloat(response).toFixed(3);
        }
      );
    }

    if (this.continueDarts && this.continueDarts.wuerfe?.length > 0) {
      this.sortContinueDartThrows();
      let lastThrow = this.continueDarts.wuerfe[this.continueDarts.wuerfe.length - 1];

      // Setze letztes geworfenes Segment
      this.segment = lastThrow.throwNumber.toString();

      // Ist letzter Spielerwurf auch letzter Spieler in der Runde gewesen?
      const playerIndex = this.players.findIndex(player => player.id === lastThrow.playerId);
      if (lastThrow.playerId === this.players[this.players.length - 1].id) {
        this.segment = this.segment === '20' ? 'Bulls' : (parseInt(this.segment) + 1).toString();
      }
      this.playerOrder = (playerIndex + 1) % this.players.length;

      const playerIds = [
        this.continueDarts.playerId1,
        this.continueDarts.playerId2,
        this.continueDarts.playerId3,
        this.continueDarts.playerId4
      ];

      this.playerScores = playerIds.map(playerId => {
        if (playerId) {
          return this.continueDarts!.wuerfe
            .filter(wurf => wurf.playerId === playerId)
            .reduce((acc, wurf) => acc + wurf.throwValue, 0);
        } else {
          return 0;
        }
      });
    }
  }

  // Registriere einen Treffer und aktualisiere den Wurf
  registerHit(value: number) {
    const currentPlayer = this.players[this.playerOrder];

    this.dartsService.updateDartThrow(this.dartGameId, currentPlayer.id ?? 'default-id', this.segment == 'Bulls' ? 21 : parseInt(this.segment, 10), value).subscribe();

    this.dartsService.getAverageForPlayer(currentPlayer.id ?? 'default-id', this.segment == 'Bulls' ? 21 : parseInt(this.segment, 10)).subscribe(
      response => {
        this.averageQuoteForCurrentPlayer = parseFloat(response).toFixed(3);
      });
    this.updateScores(value);

    this.erhoeheWurf();

    // Zeige das Punkte-Popup an
    this.scoreIncrease[this.playerOrder] = value;
    this.showPopup[this.playerOrder] = true;

    // Wechsle zum nächsten Spieler
    this.playerOrder = (this.playerOrder + 1) % this.players.length;

    // Verstecke das Popup nach 1,5 Sekunden
    setTimeout(() => {
      this.showPopup[this.playerOrder] = false;
    }, 1500);
  }

  private erhoeheWurf() {
    if (this.playerOrder === this.players.length - 1) {
      if (this.segment == "20") {
        this.segment = "Bulls";
      } else if (this.segment == "Bulls") {
        this.finishGame();
      } else {
        this.segment = this.segment == 'Bulls' ? '21' : (parseInt(this.segment, 10) + 1) + "";
      }
    }
  }

  private finishGame() {
    const newEvent = {
      id: null,
      startdate: new Date().toISOString(),
      enddate: '',
      kategorie: 'Darts',
      beschreibung: 'Spiel Around the Clock abgeschlossen',
      associatedId: this.dartGameId
    };
    this.eventService.addEvent(newEvent).subscribe();
    this.onGameEnd.emit(this.playerScores);
  }


  updateScores(value: number) {
    this.playerScores[this.playerOrder] += value;
  }

  @Output() backToMain = new EventEmitter<void>();

  goBack() {
    window.location.reload()
  }

  private sortContinueDartThrows() {
    // Erstelle eine Map zur schnellen Zuordnung der Spielerreihenfolge basierend auf `players`
    const playerOrderMap = new Map(this.players.map((player, index) => [player.id, index]));
    // Sortiere `wuerfe` zuerst nach `throwNumber`, dann nach Reihenfolge in `players`
    this.continueDarts!.wuerfe.sort((a, b) => {
      // Sortiere primär nach `throwNumber`
      if (a.throwNumber !== b.throwNumber) {
        return a.throwNumber - b.throwNumber;
      }

      // Bei gleichem `throwNumber` sortiere nach Spielerreihenfolge in `players`
      const playerOrderA = playerOrderMap.get(a.playerId) ?? Number.MAX_VALUE;
      const playerOrderB = playerOrderMap.get(b.playerId) ?? Number.MAX_VALUE;

      return playerOrderA - playerOrderB;
    });
  }
}
