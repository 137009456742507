import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dynamic-form-field',
  templateUrl: './dynamic-form-field.component.html',
  styleUrl: './dynamic-form-field.component.scss'
})
export class DynamicFormFieldComponent {
  @Input() attribute: any; // Das aktuelle Eingabe-Attribut
  @Input() value: any;     // Der aktuelle Wert des Attributs
  @Output() valueChange = new EventEmitter<any>(); // Gibt Änderungen weiter

  onValueChange(event: any): void {
    this.valueChange.emit(event); // Gibt Änderungen des Wertes zurück
  }

  onCheckboxChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.valueChange.emit(inputElement.checked);
  }
}
