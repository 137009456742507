import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Menu } from "../model/menu"; // Hier den Menu-Typ importieren
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  baseUrl = `${environment.apiUrl}/api/menu`;

  constructor(private http: HttpClient) { }

  getMenuList() {
    return this.http.get<Menu[]>(`${this.baseUrl}/alleSorten`);
  }

  getAll() {
    return this.http.get<Menu[]>(`${this.baseUrl}/all`);
  }

  addMenu(data: Menu) {
    return this.http.post<Menu>(`${this.baseUrl}/create`, data);
  }

  deleteMenu(id: Menu['id']) {
    return this.http.delete<void>(`${this.baseUrl}/delete/${id}`);
  }
}
