import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {FreundeService} from "../../service/freunde.service";
import {Freunde, friendToString} from "../../model/freunde";
import {environment} from "../../../environments/environment";
import {UploadService} from "../../service/upload.service";
import {FileUploadEvent} from "primeng/fileupload";
import {Attribute} from "../../model/attribute";
import {ChatGptService} from "../../service/chatgpt.service";
import {OverlayPanel} from "primeng/overlaypanel";
import {firstValueFrom} from 'rxjs';
import {Stepper} from "primeng/stepper";
import {AutoCompleteSelectEvent} from "primeng/autocomplete";
import {EventsService} from "../../service/events.service";
import {Events} from "../../model/events";
import {ActivatedRoute} from "@angular/router";
import {response} from "express";

@Component({
  selector: 'app-add-friend',
  templateUrl: './add-friend.component.html',
  styleUrl: './add-friend.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class AddFriendComponent implements OnInit {
  @Input() editFriend: Freunde | null = null;
  @Output() backToMain = new EventEmitter<unknown>();
  @ViewChild('overlayP') overlayPanel!: OverlayPanel;
  @ViewChild('customStepper') customStepper!: Stepper;

  active: number | undefined | null | WritableSignal<number | undefined | null>;
  currentFriend: Freunde = {
    id: null,
    vorname: '',
    nachname: '',
    fullname: '',
    geburtstag: '',
    photo: '',
    geschlecht: '',
    attributes: [],
    connection1: [],
    connection2: [],
    connections: [],
  };
  previewImageUrl: string | undefined = undefined;

  personenattribute: string = '';
  notiz: string = '';
  notizen: Events[] = [];
  //KI Variablen
  generatedQuestion: string = '';
  answerType: string = 'multipleChoice';
  answerOptions: string[] = [];
  selectedAnswers: string[] = [];
  selectedAnswer: string = '';
  freeTextAnswer: string = '';
  questionInfluence: string = '';

  allFriendsNames: { label: string; value: string }[] = [];
  allFriendsNamesMap: { [id: string]: string } = {}
  filteredKidNamesArray: { label: string; value: string }[][] = [];
  filteredPartner: { label: string; value: string }[] = [];
  partnerId2: string | null = null;
  kidsCount: number = 0;

  business = [
    {id: 'salary', label: 'Gehalt', key: 'Gehalt', type: 'text'},
    {id: 'job', label: 'Beruf', key: 'Beruf', type: 'text'},
    {id: 'firma', label: 'Arbeitgeber', key: 'Arbeitgeber', type: 'text'}
  ];

  facts = [
    {id: 'distance', label: 'Entfernung', key: 'Entfernung', type: 'number'},
    {id: 'activity', label: 'Aktivität', key: 'Aktivität', type: 'number'},
    {id: 'fifa', label: 'Fifa Spieler?', key: 'Fifa Spieler?', type: 'checkbox'},
    {id: 'board-games', label: 'Brettspieler', key: 'Brettspieler', type: 'checkbox'},
    {id: 'poker', label: 'Pokern', key: 'Pokern', type: 'checkbox'},
    {id: 'sauna', label: 'Sauna', key: 'Sauna', type: 'checkbox'},
    {id: 'weed', label: 'Weed', key: 'Weed', type: 'checkbox'},
  ];

  activities = [
    {id: 'soccer', label: 'Fußballverein', key: 'Fußball', type: 'text'},
    {id: 'athletic', label: 'Sportlich', key: 'Sportlich', type: 'checkbox'},
    {id: 'ski', label: 'Skifahrer', key: 'Skifahrer', type: 'checkbox'},
    {id: 'motorcycle', label: 'Motorrad', key: 'Motorrad', type: 'checkbox'},
  ];

  tiere = [
    {id: 'tiere', label: 'Tiere', key: 'tiere', type: 'text'},
  ];

  foodAndDrinks = [
    {id: 'drink', label: 'Lieblingsgetränk', key: 'drink', type: 'text'},
    {id: 'eat', label: 'Lieblingsessen', key: 'eat', type: 'text'},
  ];

  profileAttributes = [
    {id: 'nickname', label: 'Spitzname', key: 'spitzname', type: 'text', placeholder: 'Spitzname'},
    {id: 'email', label: 'E-Mail', key: 'email', type: 'email', placeholder: 'E-Mail-Adresse'},
    {id: 'schulabschluss', label: 'Schulabschluss', key: 'schulabschluss', type: 'text', placeholder: 'Schulabschluß'},
    {id: 'nationality', label: 'Nationalität', key: 'nationalitaet', type: 'text', placeholder: 'Nationalität'},
    {id: 'birthplace', label: 'Geburtsort', key: 'geburtsort', type: 'text', placeholder: 'Geburtsort'},
    {id: 'residence', label: 'Wohnort', key: 'wohnort', type: 'text', placeholder: 'Wohnort'},
    {id: 'native-language', label: 'Muttersprache', key: 'muttersprache', type: 'text', placeholder: 'Muttersprache'},
    {id: 'religion', label: 'Religion', key: 'religion', type: 'text', placeholder: 'Religion'}
  ];


  constructor(private freundeService: FreundeService,
              private uploadService: UploadService,
              private chatGPTService: ChatGptService,
              private eventsService: EventsService,
              private cdr: ChangeDetectorRef,
              private route: ActivatedRoute) {
    this.active = signal<number | undefined | null>(null);
  }

  submitProfile() {
    if (this.currentFriend) {
      const createPersonPromises: Promise<any>[] = []; // Liste von Promises für neue Personen

      this.currentFriend.connection1.forEach(connection => {
        connection.id1 = this.currentFriend.id || '';

        // Überprüfen, ob id2 ein Name ist und die entsprechende ID ermitteln
        if (typeof connection.id2 === 'string' && this.allFriendsNamesMap) {
          const foundId = Object.keys(this.allFriendsNamesMap).find(
            key => this.allFriendsNamesMap[key] === connection.id2
          );

          if (foundId) {
            // Wenn der Name gefunden wurde, setze die ID
            connection.id2 = foundId;
          } else if (!Object.keys(this.allFriendsNamesMap).includes(connection.id2)) {
            // Wenn Name nicht in der Map ist, handle es als neuen Namen
            const currentFriend: Freunde = {
              id: null,
              vorname: connection.id2,
              nachname: this.currentFriend.nachname,
              fullname: '',
              geburtstag: '2024-01-01',
              photo: '',
              geschlecht: connection.geschlecht || 'männlich',
              attributes: [],
              connection1: [],
              connection2: [],
              connections: [],
            };

            // Erstelle eine neue Person und füge ein Promise zur Liste hinzu
            const promise = firstValueFrom(this.freundeService.addFreund(currentFriend)).then((newPerson: any) => {
              const person = newPerson as Freunde;
              if (person && person.id) {
                connection.id2 = person.id; // Setze die neue ID
              } else {
                console.error('Fehler: API hat keine gültige Person zurückgegeben.');
              }
            });

            createPersonPromises.push(promise);
          }
        }

        if (typeof connection.id2 === 'object' && connection.id2 !== null) {
          connection.id2 = (connection.id2 as { value: string }).value;
        }
      });

      // Warte auf alle neuen Personen, bevor das Profil gespeichert wird
      Promise.all(createPersonPromises).then(() => {
        this.currentFriend.photo = this.extractFileName(this.currentFriend.photo);
        this.freundeService.addFreund(this.currentFriend).subscribe(() => {
          this.backToMain.emit();
        });
      });
    }
  }

  initializeComponent() {
    this.loadFriends();
    if (!this.editFriend) {
      this.currentFriend = this.createNewObject();
    } else {
      this.currentFriend = this.editFriend;
    }
    if (this.editFriend && this.editFriend.photo) {
      this.previewImageUrl = this.editFriend.photo.replace('thumbnails/', '');
    } else {
      this.previewImageUrl = undefined;

    }
    if (this.currentFriend.connection1 === undefined) {
      this.currentFriend.connection1 = []; // Initialisiere, falls undefined

    }
    if (this.currentFriend.connection1.length > 0) {
      this.filteredKidNamesArray = Array(this.currentFriend.connection1.length).fill([]);
    }

  }

  ngOnInit(): void {
    const friendId = this.route.snapshot.paramMap.get('id');
    if (friendId) {
      this.freundeService.getById(friendId).subscribe((response) => {
        this.editFriend = response;
        this.initializeComponent();
        this.cdr.detectChanges();
      });
    } else {
      this.initializeComponent();
    }
  }


  resolveConnectionNames() {
    this.currentFriend.connection1.forEach(connection => {
      if (connection.id2 && this.allFriendsNamesMap[connection.id2]) {
        connection.id2 = this.allFriendsNamesMap[connection.id2];
      }
    });
  }

  loadFriends() {
    this.freundeService.getAlle().subscribe((freund: Freunde[]) => {
      this.allFriendsNamesMap = {}; // Reset der Map
      freund.forEach(kind => {
        this.allFriendsNamesMap[kind.id ?? ''] = kind.fullname; // Map ID -> Name
      });

      this.allFriendsNames = freund.map(kind => ({
        label: kind.fullname, // Angezeigter Name
        value: kind.id ?? ''  // Gespeicherte ID mit Fallback auf leeren String
      }));
      const partnerConnection = this.getPartnerConnections()?.[0];
      if (partnerConnection?.id2) {
        this.partnerId2 = this.allFriendsNamesMap[partnerConnection.id2] || null;
      } else {
        this.partnerId2 = null;
      }
      this.resolveConnectionNames();
    });
  }

  onKidsNumberChange(kidsCount: string) {
    const count = parseInt(kidsCount, 10) || 0;

    if (count > 0) {
      // Füge fehlende Verbindungen hinzu
      while (this.currentFriend.connection1.length < count) {
        const newIndex = this.currentFriend.connection1.length + 1;
        this.currentFriend.connection1.push({
          id: '',
          id1: this.currentFriend.id || '', // ID der aktuellen Person
          id2: '', // ID des verbundenen Kindes (initial leer)
          beschreibung: `Kind ${newIndex}`, // Beschreibung der Verbindung
          geschlecht: ''
        });
      }

      // Entferne überschüssige Verbindungen
      if (this.currentFriend.connection1.length > count) {
        this.currentFriend.connection1.splice(count);
      }

      // Aktualisiere die gefilterten Namen
      this.filteredKidNamesArray = Array(this.currentFriend.connection1.length).fill([]);
    } else {
      // Keine Kinder, Verbindungen leeren
      this.currentFriend.connection1 = [];
      this.filteredKidNamesArray = [];
    }
  }

  filterPartner(event: { query: string }) {
    const query = event.query.toLowerCase();
    this.filteredPartner = Object.entries(this.allFriendsNamesMap)
      .filter(([id, name]) => name.toLowerCase().includes(query))
      .map(([id, name]) => ({label: name, value: id}))
      .sort((a, b) => {
        const firstNameA = a.label.split(' ')[0].toLowerCase();
        const firstNameB = b.label.split(' ')[0].toLowerCase();
        return firstNameA.localeCompare(firstNameB);
      });
  }

  filterKidNames(event: { query: string }, index: number) {
    const query = event.query.toLowerCase();
    this.filteredKidNamesArray[index] = Object.entries(this.allFriendsNamesMap)
      .filter(([id, name]) => name.toLowerCase().includes(query))
      .map(([id, name]) => ({label: name, value: id}));
  }

  navigateToPerson(personId: string) {
    const id = this.allFriendsNamesMap[personId] ? personId : Object.keys(this.allFriendsNamesMap).find(key => this.allFriendsNamesMap[key] === personId);

    if (!id) {
      console.error('Fehler: Keine gültige ID für den angegebenen Namen gefunden:', personId);
      return;
    }

    // Rufe die Person basierend auf der ID ab
    this.freundeService.getById(id).subscribe(response => {
      this.editFriend = {...response}; // Neue Referenz zuweisen
      this.customStepper.activeStep = 0;
      this.editFriend.photo = `${environment.downloadUrl}${this.editFriend.photo}`;
      this.initializeComponent();
      this.cdr.detectChanges();
    });
  }

  onFileSelected(event: any) {
    const file = event.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.previewImageUrl = reader.result as string;
      };
      reader.readAsDataURL(file);
      this.currentFriend.photo = file.name;
      this.uploadService.uploadPhoto(file).subscribe(response => {
        if (this.currentFriend) {
          const fileName = response.url.split('/').pop();
          this.currentFriend.photo = fileName ? fileName : response.url;
          this.previewImageUrl = `${environment.downloadUrl}${this.currentFriend.photo}`;
        }
      });
    }
  }

  getAttributeValue(attributeName: string): string {
    const attribute = this.currentFriend?.attributes?.find(attr => attr.attribut === attributeName);
    return attribute ? attribute.value || '' : '';
  }

  createNewObject() {
    return {
      id: null,
      vorname: '',
      nachname: '',
      fullname: '',
      geburtstag: '',
      geschlecht: '',
      photo: '',
      attributes: [],
      connection1: [],
      connection2: [],
      connections: [],
    }
  }

  setAttributeValue(attributeName: string, value: string) {
    if (!this.currentFriend) {
      this.currentFriend = this.createNewObject();
    }

    if (!this.currentFriend.attributes) {
      this.currentFriend.attributes = [];
    }

    const attribute = this.currentFriend.attributes.find(attr => attr.attribut === attributeName);
    if (attribute) {
      attribute.value = value;
    } else {
      this.currentFriend.attributes.push({id: '', idConnection: this.currentFriend.id, attribut: attributeName, value});
    }
  }

  getAttribute(key: string): string {
    const attribute = this.currentFriend.attributes.find(attr => attr.attribut === key);
    return attribute && attribute.value ? attribute.value : '';
  }

  setAttribute(key: string, value: string): void {
    const attribute = this.currentFriend.attributes.find(attr => attr.attribut === key);
    if (attribute) {
      attribute.value = value;
    } else {

    }
  }

  onBasicUploadAuto($event: FileUploadEvent) {
  }

  isAnswerInvalid(): boolean {
    if (this.answerType === 'multipleChoice' || this.answerType === 'yesNo') {
      return !this.selectedAnswers;
    } else if (this.answerType === 'freeText') {
      return !this.freeTextAnswer.trim();
    }
    return true;
  }

  saveAnswer(event: Event) {
    if (!this.currentFriend || !this.generatedQuestion) {
      return;
    }

    this.currentFriend.photo = this.extractFileName(this.currentFriend.photo);
    let combinedAnswer = [...this.selectedAnswers]; // Kopiere Multiple-Choice-Antworten

    // Füge Ja/Nein hinzu, falls vorhanden
    if (this.selectedAnswer.trim()) {
      combinedAnswer.push(this.selectedAnswer.trim());
    }

    // Füge Freitext-Antwort hinzu, falls vorhanden
    if (this.freeTextAnswer.trim()) {
      combinedAnswer.push(this.freeTextAnswer.trim());
    }

    const newAttribute: Attribute = {
      id: '',
      idConnection: this.currentFriend.id,
      attribut: this.generatedQuestion,
      value: combinedAnswer.join(', ') // Alle Antworten als String speichern
    };

    // Attribut zur Liste hinzufügen
    if (!this.currentFriend.attributes) {
      this.currentFriend.attributes = [];
    }
    this.currentFriend.attributes.push(newAttribute);

    // Update aufrufen, um die Änderungen in der Datenbank zu speichern
    this.freundeService.update(this.currentFriend).subscribe({
      next: (response) => {
        this.selectedAnswer = '';
        this.freeTextAnswer = '';
        this.selectedAnswers = [];
        this.openQuestionnaire(event); // Neue Frage generieren
      }
    });
  }

  extractFileName(url: string): string {
    if (!url) {
      return '';
    }
    const segments = url.split('/');
    return segments[segments.length - 1]; // Der letzte Teil ist der Dateiname
  }

  toggle(event: Event) {
    this.openQuestionnaire(event);
    this.overlayPanel.toggle(event);
  }

  openQuestionnaire(event: Event) {
    const message = this.generateQuestionText();

    // Frage mit Anpassung beeinflussen
    const fullMessage = this.questionInfluence
      ? `${message}\nAnpassung der Fragestellung: Bitte fokussiere die Frage auf ${this.questionInfluence}`
      : message;

    this.chatGPTService.profileQuestion(fullMessage).subscribe({
      next: (response: string) => {
        const parsedResponse = this.parseResponse(response);

        if (parsedResponse) {
          this.generatedQuestion = parsedResponse.question;
          this.answerType = parsedResponse.answerType; // 'multipleChoice', 'yesNo', 'freeText'
          this.answerOptions = parsedResponse.options || []; // Nur für multipleChoice oder ja/nein
        }
      }
    });
  }

  generateQuestionText() {
    const info = friendToString(this.currentFriend);
    return "Ich habe folgende Information über diese Person vorliegen: " + info +
      "\n Erzeuge mir eine beliebe Frage die ich über diese Person noch nicht kenne. " +
      "Liefe mir Fragen die entweder aus Multiple Choice oder Ja/Nein/keine Ahnung bestehen. " +
      "Liefere mir immer nur eine einzige Frage. " +
      "Beziehe die Fragen eher auf die Persönlichkeit. Stelle keine Fragen doppelt." +
      "Markiere die Antwort mit diesem Flag ***MultipleChoice oder ***Boolean damit ich systematisch die Antwort interpretieren kann. " +
      "e.g Beispiele: Welche Rolle spielt diese Person in deinem Leben? ***MultipleChoice\n Optionen: [Familie, Freund, Kollege, Bekannter] " +
      "Bist du regelmäßig mit dieser Person in Kontakt? ***Boolean\n"
  }

  generatePersonenbeschreibung() {
    const info = friendToString(this.currentFriend);
    return "Ich habe folgende Information über diese Person vorliegen: " + info + ". Erzeuge mir dazu eine Personenbeschreibung."
  }

  parseResponse(response: string): { question: string; answerType: string; options?: string[] } | null {
    const multipleChoiceFlag = '***MultipleChoice';
    const booleanFlag = '***Boolean';
    const freeTextFlag = '***FreieTextauswahl';

    let answerType: string;
    let question: string;
    let options: string[] | undefined;

    // Bestimmen des Antworttyps basierend auf den Flags
    if (response.includes(multipleChoiceFlag)) {
      answerType = 'multipleChoice';
    } else if (response.includes(booleanFlag)) {
      answerType = 'yesNo';
      options = ['Ja', 'Nein', 'Vielleicht', 'keine Ahnung'];
    } else if (response.includes(freeTextFlag)) {
      answerType = 'freeText';
    } else {
      // Unbekanntes Format
      return null;
    }

    // Extrahiere die Frage (alles vor dem ersten Flag)
    const flagIndex = response.indexOf('***');
    question = response.slice(0, flagIndex).trim();

    // Optionen extrahieren, falls multipleChoice
    if (answerType === 'multipleChoice') {
      const optionsMatch = response.match(/Optionen: \[(.*?)\]/); // Erwartet Format: "Optionen: [Option1, Option2, ...]"
      if (optionsMatch && optionsMatch[1]) {
        options = optionsMatch[1].split(',').map(option => option.trim());
      }
    }

    return {question, answerType, options};
  }

  showPersonenbeschreibung() {
    this.chatGPTService.profileQuestion(this.generatePersonenbeschreibung()).subscribe({
      next: (response: string) => {
        this.personenattribute = response;
      }
    });
  }

  getKidsConnections() {
    let connections = this.currentFriend.connection1.filter(con => con.beschreibung?.includes("Kind"));
    return connections;
  }

  getPartnerConnections() {
    return this.currentFriend.connection1.filter(con => con.beschreibung?.includes("Partner"));
  }

  getKidsCount() {
    return this.currentFriend?.connection1?.filter(con => con.beschreibung?.includes("Kind"))?.length || 0;
  }

  updatePartnerConnection(event: AutoCompleteSelectEvent) {
    const newPartner = event.value?.value; // 'value' enthält die ID des ausgewählten Partners
    if (!newPartner) {
      return;
    }

    const partnerConnection = this.getPartnerConnections()?.[0];
    if (partnerConnection) {
      partnerConnection.id2 = newPartner;
    } else {
      // Entferne alle Verbindungen mit der Beschreibung "Partner" aus connection1 und connection2
      this.currentFriend.connection1 = this.currentFriend.connection1.filter(
        connection => connection.beschreibung !== 'Partner'
      );
      this.currentFriend.connection2 = this.currentFriend.connection2.filter(
        connection => connection.beschreibung !== 'Partner'
      );

      this.currentFriend.connection1.push({
        id: '',
        id1: this.currentFriend.id || '',
        id2: newPartner,
        beschreibung: 'Partner'
      });
      this.currentFriend.connection2.push({
        id: '',
        id1: newPartner,
        id2: this.currentFriend.id || '',
        beschreibung: 'Partner'
      });
    }
  }

  showPersonenattribute() {
    if (this.personenattribute) {
      this.personenattribute = '';
      this.notiz = '';
    } else {
      this.notiz = '';
      this.personenattribute = friendToString(this.currentFriend);
    }
  }

  showNotiz() {
    if (this.notiz) {
      this.notiz = '';
      this.personenattribute = '';
    } else {
      this.notiz = ' ';
      this.personenattribute = '';
    }
  }

  saveNotiz() {
    const newEvent = {
      id: null,
      startdate: new Date().toISOString(),
      enddate: '',
      kategorie: 'Notiz',
      beschreibung: this.notiz.trim(),
      associatedId: this.currentFriend.id || ''
    };
    this.eventsService.addEvent(newEvent).subscribe();
    this.notiz = '';
  }

  showExistingNotiz(): void {
    if (this.notizen && this.notizen.length > 0) {
      this.notizen = [];
      this.cdr.detectChanges();
    }
    this.eventsService.getAlleByAssociatedId(this.currentFriend.id!).subscribe(
      (value: Events[]) => {
        this.notizen = value.map(event => ({
          ...event,
          displayLabel: `Erstellt: ${new Date(event.startdate).toLocaleDateString()}: ${event.beschreibung}`
        }));
      }
    );
  }

}
