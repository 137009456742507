<div class="sidebar-wrapper">

  <div class="logo">
    <div>
      <img src="assets/igor.png" alt="Logo" class="logo-img">
    </div>
    <span class="my-simple-text">My Life</span>
  </div>

  <ul class="nav">
    <li routerLinkActive="active">
      <a routerLink="home">
        <p style="font-size: 24px;">Home</p>
      </a>
    </li>


    <li routerLinkActive="active">
      <a routerLink="todo-list">
        <p style="font-size: 24px;">ToDo-Liste</p>
      </a>
    </li>

    <li>
      <a routerLink="freunde">
        <p style="font-size: 24px;">Freunde</p>
      </a>
    </li>

    <li routerLinkActive="active">
      <a routerLink="darts">
        <p style="font-size: 24px;">Darts</p>
      </a>
    </li>
    <li routerLinkActive="active">
      <a routerLink="maps">
        <p style="font-size: 24px;">Maps</p>
      </a>
    </li>
    <li routerLinkActive="active">
      <a routerLink="timeline">
        <p style="font-size: 24px;">Timeline</p>
      </a>
    </li>
    <li routerLinkActive="active">
      <a routerLink="kalender">
        <p style="font-size: 24px;">Kalender</p>
      </a>
    </li>
    <li routerLinkActive="active">
      <a routerLink="weed-menu">
        <p style="font-size: 24px;">Menü</p>
      </a>
    </li>
    <li routerLinkActive="active">
      <a routerLink="inventar">
        <p style="font-size: 24px;">Inventar</p>
      </a>
    </li>
  </ul>
</div>

