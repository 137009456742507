<div style="display: flex; justify-content: center; width: 100%;">
  <p-button label="Zurück zum High Score" icon="pi pi-arrow-left" (click)="backToHome()"></p-button>
</div>
<div *ngIf="selectedGame" class="selected-game-details">
  <div class="game-summary">
    <h3>Spiel Details</h3>
    <p>Spieltyp: {{ selectedGame.gameTyp }}</p>
    <p>Datum: {{ selectedGame.createdDate | date:'dd.MM.yyyy HH:mm' }}</p>
    <p>Punkte: {{ selectedGame.points }}</p>
  </div>
  <div class="game-details">
    <h4>Wurfdaten:</h4>
    <table class="wurf-table">
      <thead>
      <tr>
        <th>Wurfnummer</th>
        <th>Wurfwert</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let wurf of selectedGame.wuerfe">
        <td>{{ wurf.throwNumber }}</td>
        <td>{{ wurf.throwValue }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

