<div class="background">
  <div class="sidebar" data-color="black" data-active-color="danger">
    <app-sidebar-cmp></app-sidebar-cmp>
  </div>

  <div class="main-panel bg-transparent">
    <app-navbar-cmp></app-navbar-cmp>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
