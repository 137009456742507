<div *ngIf="showCalender" class="calenderDiv">
<span class="icn" (click)="onButtonClick($event)">
  <i class="fas fa-circle-plus"></i>
</span>

  <div>
    <p-calendar [(ngModel)]="date" dateFormat="dd.mm.yy" inline="true" [numberOfMonths]="numberOfMonths"
                styleClass="custom-calendar">
      <ng-template let-date pTemplate="date">
      <span (contextmenu)="onRightClick($event, date)"
            [ngClass]="{'today': isToday(date), 'has-event': hasEventForDate(date)}"
            class="date-circle">
       {{ date.day }}
      </span>
      </ng-template>
    </p-calendar>

    <h2 style="padding-top:20px">
      Events für den {{ date.getDate() }}.{{ date.getMonth() + 1 }}.{{ date.getFullYear() }}:</h2>
    <div *ngIf="getEventsForDate(date).length > 0" style="font-size: 20px">
      <ul>
        <li *ngFor="let event of getEventsForDate(date)">
          {{ event.beschreibung }}
        </li>
      </ul>
    </div>
  </div>
</div>


<div *ngIf="showAddEvent">
  <app-add-event [startDate]="date" (closeEvent)="closeOverlay()"></app-add-event>
</div>
