import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import {Klamotten} from "../model/klamotten";
import {Modellautos} from "../model/modellautos";
import {Technik} from "../model/technik";

@Injectable({
  providedIn: 'root',
})
export class InventarService {
  baseUrl = `${environment.apiUrl}/api/inventar`;

  constructor(private http: HttpClient) {}

  //******************* Klamotten ********************************
  createKlamotten(klamotten: Klamotten): Observable<Klamotten> {
    return this.http.post<Klamotten>(`${this.baseUrl}/klamotten`, klamotten);
  }

  getKlamotten(id: string): Observable<Klamotten> {
    return this.http.get<Klamotten>(`${this.baseUrl}/klamotten/${id}`);
  }

  getAlleKlamotten(): Observable<Klamotten[]> {
    return this.http.get<Klamotten[]>(`${this.baseUrl}/klamotten`);
  }

  deleteKlamotten(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/klamotten/${id}`);
  }

  //******************* Modellautos ********************************
  createModellauto(modellauto: Modellautos): Observable<Modellautos> {
    return this.http.post<Modellautos>(`${this.baseUrl}/modellautos`, modellauto);
  }

  getModellauto(id: string): Observable<Modellautos> {
    return this.http.get<Modellautos>(`${this.baseUrl}/modellautos/${id}`);
  }

  getAlleModellautos(): Observable<Modellautos[]> {
    return this.http.get<Modellautos[]>(`${this.baseUrl}/modellautos`);
  }

  deleteModellauto(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/modellautos/${id}`);
  }

  //******************* Technik ********************************
  createTechnik(technik: Technik): Observable<Technik> {
    return this.http.post<Technik>(`${this.baseUrl}/technik`, technik);
  }

  getTechnik(id: string): Observable<Technik> {
    return this.http.get<Technik>(`${this.baseUrl}/technik/${id}`);
  }

  getAlleTechnik(): Observable<Technik[]> {
    return this.http.get<Technik[]>(`${this.baseUrl}/technik`);
  }

  deleteTechnik(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/technik/${id}`);
  }
}
