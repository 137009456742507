<p-toast life="1000"></p-toast>
<div class="global-actions">
  <input pInputText type="text" placeholder="Namen filtern" change (input)="applyFilter($event)" class="action-button" style="width: 100px;"/>
  <input pInputText type="text" placeholder="Attribute filtern" change (input)="applyAttributeFilter($event)" class="action-button" style="width: 100px;"/>
  <button pButton type="button" icon="fa-solid fa-face-grin-stars" class="action-button" (click)="applyFilterTop()"></button>
  <button pButton type="button" icon="fa-solid fa-children" class="action-button" (click)="getAlle()"></button>
  <button pButton type="button" icon="pi pi-user-plus" class="action-button" (click)="addFriend()"></button>
  <button pButton type="button" icon="pi pi-refresh" class="action-button" (click)="refreshList()"></button>
  <button *ngIf="showBackButton" pButton type="button" icon="pi pi-arrow-left" label="Zurück" class="action-button"
          (click)="back()"></button>

  <!--  Not Active buttons-->
  <!--  <button *ngIf="!showTableView" pButton type="button" icon="pi pi-table" class="action-button" (click)="toggleView()"></button>-->
  <!--  <button *ngIf="showTableView" pButton type="button" icon="pi pi-th-large" class="action-button" (click)="toggleView()"></button>-->
</div>

<!-- Kartenansicht -->
<div class="friends-list" *ngIf="showFriends && !showTableView" (click)="closeContextMenu()">
  <ng-container *ngFor="let friend of freunde">
    <div
      class="friend-card"
      (click)="selectFriend(friend)"
      [ngClass]="{'selected': friend === selectedFriend}"
      [ngStyle]="{ 'background-image': 'url(' + friend.photo + ')' }"
      (drop)="onDrop($event, friend)"
      (touchstart)="onTouchStart($event, friend)"
      (touchend)="onTouchEnd($event, friend)"
      (dragover)="allowDrop($event)"
      (dblclick)=editFriend(friend)
      (contextmenu)="onRightClick($event, friend)">
      <div class="friend-info">
        <span class="friend-name">{{ friend?.fullname }}</span>
      </div>
    </div>
  </ng-container>
</div>

<!-- Tabellenansicht -->
<p-table [value]="freunde" [tableStyle]="{'min-width': '50rem'}" *ngIf="showFriends && showTableView">
  <ng-template pTemplate="caption">
    <div class="flex align-items-center justify-content-between">
      Freundesliste
      <p-button icon="pi pi-refresh" (click)="refreshList()"></p-button>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>Foto</th>
      <th>Name</th>
      <th>Aktionen</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-friend>
    <tr (click)="selectFriend(friend)" [ngClass]="{'selected': friend === selectedFriend}">
      <td><img [src]="friend.photo" alt="Foto von {{ friend.fullname }}" width="50" class="shadow-4"/></td>
      <td>{{ friend.fullname }}</td>
      <td>
        <button pButton type="button" severity="info" icon="pi pi-user-edit" class="action-button"
                (click)="editFriend(friend)"></button>
        <button pButton type="button" severity="danger" icon="pi pi-trash" class="action-button"
                (click)="deleteFriend(friend)"></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    <div class="p-table-summary">
      Insgesamt gibt es {{ freunde ? freunde.length : 0 }} Freunde.
    </div>
  </ng-template>
</p-table>


<!-- Kontextmenü -->
<div *ngIf="showContextMenuFlag"
     class="context-menu"
     [ngStyle]="{'top.px': contextMenuPosition.y, 'left.px': contextMenuPosition.x}">
  <button (click)="editFriend(contextMenuFriend)">Edit</button>
  <button (click)="deleteFriend(contextMenuFriend)">Löschen</button>
  <button (click)="addConnection(contextMenuFriend)">Connection</button>
</div>


<!-- Ansicht zum Freund Hinzufügen oder Bearbeiten -->
<div *ngIf="showAddFriend">
  <app-add-friend
    (backToMain)="back()"
    [editFriend]="selectedFriend"></app-add-friend>
</div>

<!-- Ansicht zum Hinzufügen einer Connection zwischen Personen -->
<div *ngIf="showAddConnection">
  <app-add-connection
    (backToMain)="back()"
    [selectedFriend]="selectedFriend"
    [allFriends]="freunde">
  </app-add-connection>
</div>
