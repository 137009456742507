<ng-container [ngSwitch]="attribute.type">
  <!-- Number Input -->
  <div *ngSwitchCase="'number'">
    <p-floatLabel>
      <input
        type="number"
        pInputText
        [id]="attribute.id"
        [ngModel]="value"
        (ngModelChange)="onValueChange($event)"
      />
      <label [for]="attribute.id">{{ attribute.label }}</label>
    </p-floatLabel>
  </div>

  <!-- Checkbox Input -->
  <div *ngSwitchCase="'checkbox'">
    <input
      type="checkbox"
      [id]="attribute.id"
      [checked]="value"
      (change)="onCheckboxChange($event)"
    />
    <label [for]="attribute.id">{{ attribute.label }}</label>
  </div>

  <!-- Default Input -->
  <div *ngSwitchDefault>
    <p-floatLabel>
      <input class="w-100" type="text" pInputText [id]="attribute.id" [ngModel]="value"
             (ngModelChange)="onValueChange($event)"/>
      <label [for]="attribute.id">{{ attribute.label }}</label>
    </p-floatLabel>
  </div>
</ng-container>
