import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-item-add-klamotten',
  templateUrl: './item-add-klamotten.component.html',
  styleUrl: './item-add-klamotten.component.scss'
})
export class ItemAddKlamottenComponent {
  @Output() backToMain = new EventEmitter<unknown>();

}
