<div class="timeline-container">
  <p-table [value]="events" [paginator]="true" [rows]="10" [responsiveLayout]="'scroll'" class="p-datatable-gridlines">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 10%;">Datum</th>
        <th style="width: 5%;">Typ</th>
        <th style="width: 10%;">Bild</th>
        <th style="width: 75%;">Beschreibung</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item>
      <tr>
        <td>{{ item.startdate | date:'dd.MM.yyyy HH:mm:ss' }}</td>
        <td>{{ item.kategorie }}</td>
        <td>
          <p-image [src]="item.associatedId" width="50" class="shadow-4"/>
        </td>
        <td>{{ item.beschreibung }}</td>
      </tr>
    </ng-template>
  </p-table>

</div>
