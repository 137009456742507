import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { DartsService } from "../../service/darts.service";
import { FreundeService } from "../../service/freunde.service";
import { Freunde } from "../../model/freunde";
import { Dart } from "../../model/darts";
import {encapsulateStyle} from "@angular/compiler";

@Component({
  selector: 'app-dart-show-games',
  templateUrl: './dart-show-games.component.html',
  styleUrls: ['./dart-show-games.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DartShowGamesComponent{
  recentGames: Dart[] = [];
  friendsList: Freunde[] = [];
  @Input() selectedGame!: Dart;
  @Output() backToMain = new EventEmitter<unknown>();


  constructor(private dartsService: DartsService,
              private friendService: FreundeService) {
    this.dartsService.getRankedHighScore().subscribe(dartGames => {
      this.recentGames = dartGames.filter(game => this.isGameComplete(game));
    });

    this.friendService.getAlle().subscribe(friends => {
      this.friendsList = friends;
    });
  }

  private isGameComplete(game: Dart): boolean {
    const REQUIRED_THROWS_PER_PLAYER = 21;

    // Ermitteln der teilnehmenden Spieler
    const totalPlayers = [game.playerId1, game.playerId2, game.playerId3, game.playerId4]
      .filter(playerId => playerId !== null && playerId !== undefined);

    // Zählen der Würfe pro Spieler
    const playerThrowsCount = game.wuerfe.reduce((darts, throwData) => {
      if (throwData.playerId) {
        darts[throwData.playerId] = (darts[throwData.playerId] || 0) + 1;
      }
      return darts;
    }, {} as Record<string, number>);

    // Prüfen, ob jeder teilnehmende Spieler die erforderliche Anzahl an Würfen gemacht hat
    return totalPlayers.every(playerId => playerThrowsCount[playerId] === REQUIRED_THROWS_PER_PLAYER);
  }

  backToHome() {
    this.backToMain.emit();
  }
}
