<div class="home">
  <a routerLink="/todo-list" class="link-item">
    <img src="assets/todo-liste.jpeg" alt="ToDo-Liste" class="image">
    <p class="app-label">ToDo-Liste</p>
  </a>
  <a routerLink="/freunde" class="link-item">
    <img src="assets/freunde.jpeg" alt="Freunde" class="image">
    <p class="app-label">Profile</p>
  </a>
  <a routerLink="/darts" class="link-item">
    <img src="assets/darts.jpg" alt="Darts" class="image">
    <p class="app-label">Darts</p>
  </a>
  <a routerLink="/maps" class="link-item">
    <img src="assets/maps.png" alt="Maps" class="image">
    <p class="app-label">Maps</p>
  </a>
  <a routerLink="/timeline" class="link-item">
    <img src="assets/timeline.png" alt="Timeline" class="image">
    <p class="app-label">Timeline</p>
  </a>
  <a routerLink="/kalender" class="link-item">
    <img src="assets/kalender.png" alt="Kalender" class="image">
    <p class="app-label">Kalender</p>
  </a>
  <a routerLink="/weed-menu" class="link-item">
    <img src="assets/weed.png" alt="Menü" class="image">
    <p class="app-label">Menü</p>
  </a>
  <a routerLink="/inventar" class="link-item">
    <img src="assets/inventar.png" alt="Inventar" class="image">
    <p class="app-label">Inventar</p>
  </a>
</div>
