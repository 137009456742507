import {Component, OnInit} from '@angular/core';
import {InventarService} from "../service/inventar.service";
import {Technik} from "../model/technik";
import {Inventar} from "../model/inventar";
import {Klamotten} from "../model/klamotten";
import {Modellautos} from "../model/modellautos";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-inventar',
  templateUrl: './inventar.component.html',
  styleUrl: './inventar.component.scss'
})
export class InventarComponent implements OnInit {
  items: any[] = []; // Liste der Artikel
  activeTab: string = 'Alle'; // Aktiver Tab
  tabMenuItems: any[] = []; // Menüeinträge für TabMenu

  // frame control booleans
  addKlamotten: boolean = false;
  addModellautos: boolean = false;
  addTechnik: boolean = false;
  mainScreen: boolean = true;

  selectedItem: any = undefined;

  constructor(private inventarService: InventarService) {}

  // Kategorien
  allItems: Inventar[] = [];
  technikItems: Technik[] = [];
  klamottenItems:Klamotten[] = [];
  modellautosItems:Modellautos[] = [];

  ngOnInit() {
    // Initialisiere TabMenu
    this.init();

    // Setze Standardartikel
    this.setActiveItems();
  }

  init() {
    this.allItems = [];
    this.tabMenuItems = [
      {label: 'Alle', command: () => this.setActiveTab('Alle')},
      {label: 'Technik', command: () => this.setActiveTab('Technik')},
      {label: 'Klamotten', command: () => this.setActiveTab('Klamotten')},
      {label: 'Modellautos', command: () => this.setActiveTab('Modellautos')}
    ];

    this.inventarService.getAlleTechnik().subscribe(response => {
      this.technikItems = response.map(technik => {
        return {
          ...technik,
          photo: `${environment.downloadUrl}thumbnails/${technik.photo}`
        };
      });

      this.allItems.push(...this.technikItems);
      this.setActiveItems();
    });
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
    this.setActiveItems();
  }

  setActiveItems() {
    switch (this.activeTab) {
      case 'Technik':
        this.items = [...this.technikItems]; // Neue Referenz erstellen
        break;
      case 'Klamotten':
        this.items = [...this.klamottenItems]; // Neue Referenz erstellen
        break;
      case 'Modellautos':
        this.items = [...this.modellautosItems]; // Neue Referenz erstellen
        break;
      default:
        this.items = [...this.allItems]; // Neue Referenz erstellen
        break;
    }
  }

  onAddItem() {
    // Setze alle Flags auf `false`, um sicherzustellen, dass nur das passende Item geöffnet wird
    this.mainScreen = false;
    this.addKlamotten = false;
    this.addModellautos = false;
    this.addTechnik = false;


    // Basierend auf dem aktiven Tab die richtige Komponente aktivieren
    switch (this.activeTab) {
      case 'Technik':
        this.addTechnik = true;
        break;
      case 'Klamotten':
        this.addKlamotten = true;
        break;
      case 'Modellautos':
        this.addModellautos = true;
        break;
      default:
        alert('Funktion für diesen Tab nicht verfügbar.');
        break;
    }
  }

  back() {
    this.init();
    this.mainScreen = true;
    this.addKlamotten = false;
    this.addModellautos = false;
    this.addTechnik = false;
    this.activeTab = "Technik";
    this.selectedItem = undefined;
  }

  editArtikel(item: any) {
    this.selectedItem = item;

    this.init();
    this.mainScreen = false;
    this.addKlamotten = false;
    this.addModellautos = false;
    this.addTechnik = false;

    switch (item.subject) {
      case 'Technik':
        this.addTechnik = true;
        break;
      case 'Klamotten':
        this.addKlamotten = true;
        break;
      case 'Modellautos':
        this.addModellautos = true;
        break;
      default:

        break;
    }
  }

}
