.date-circle {
  width: 53px;
  height: 53px;
  font-size: 12px;
  text-align: center;
  align-content: center;
  border: 5px solid transparent; // Korrigierte Eigenschaft für den Rand
  background-color: transparent;
  cursor: default;

  &.today {
    background-color: lightblue;
    border: 7px solid transparent;
    cursor: pointer;
  }

  &.has-event {
    border-color: #00c64c; // Korrekte Verwendung der border-Eigenschaft für die Rahmenfarbe
    background-color: #00c64c;
    cursor: pointer;
  }
}

.custom-calendar {
  height: auto;
  padding-left: -15px;
  padding-right: -15px;
}

html {
  font-size: 1.0rem;
}

.p-datepicker table td > span {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: box-shadow .2s;
  border: 1px solid transparent
}

.p-datepicker table td {
  padding: .3rem;
}

.icn {
  color: rgb(255, 255, 255);
  font-size: 30px;
  margin: 20px;
  cursor: pointer;

}

@media screen and (max-width: 768px) {
  .custom-calendar {
    display: none !important;
  }

  .custom-calendar:first-of-type {
    display: block !important;
  }

  .p-overlaypanel .p-overlaypanel-content {
    position: absolute; /* Sicherstellen, dass das Panel absolut positioniert wird */
    top: 50px; /* Abstand von oben */
    left: 0px; /* Abstand von links */
    z-index: 1000; /* Stellt sicher, dass es über anderen Elementen liegt */
  }
}
