<p-tabMenu [model]="tabMenuItems"></p-tabMenu>
<app-item-list *ngIf="mainScreen"
               [items]="items"
               [title]="activeTab | titlecase"
               (editItem)="editArtikel($event)"
               (addItem)="onAddItem()">
</app-item-list>

<app-item-add-klamotten *ngIf="addKlamotten"
                        (backToMain)="back()"></app-item-add-klamotten>

<app-item-add-modellautos *ngIf="addModellautos"
                          (backToMain)="back()"></app-item-add-modellautos>

<app-item-add-technik *ngIf="addTechnik"
                      [editItem]="selectedItem"
                      (backToMain)="back()"></app-item-add-technik>
