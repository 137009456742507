import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private role: string | null = null;

  constructor() {
    if (this.isLocalStorageAvailable()) {
      this.role = localStorage.getItem('role');
    }
  }

  private isLocalStorageAvailable(): boolean {
    try {
      return typeof localStorage !== 'undefined';
    } catch (e) {
      return false;
    }
  }

  getRole(): string {
    if (this.role) {
      return this.role;
    } else {
      return "user";
    }
  }
}
