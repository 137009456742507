<div class="leaderboard">
  <div class="leaderboard-header">Rangliste</div>
  <ul class="leaderboard-list">
    <li *ngFor="let game of rankedDartGames; let i = index"
        class="leaderboard-item"
        [ngClass]="{'first-place': i === 0}"
        (click)="loadPlayedGame(i,$event)"> <!-- Click-Handler für gesamte Zeile -->

      <div class="ranking-number">{{ i + 1 }}</div>
      <img *ngIf="game.player?.photo" [src]="game.player.photo" alt="Spieler Foto"
           class="player-photo">

      <div class="player-info">
        <p class="player-name">{{ game.player?.fullname || 'Unbekannter Spieler' }}</p>
        <p class="player-date">{{ game.createdDate | date:'dd.MM.yyyy HH:mm:ss' }}</p>
      </div>

      <!-- Emoji-Pokal für den Bestplatzierten (Index 0) -->
      <div class="trophy">
        <span *ngIf="i === 0" class="trophy-icon">🏆</span>
      </div>

      <div class="player-score" (click)="loadPlayedGame(i, $event)">{{ game.points }}</div> <!-- Click-Handler für Punkte -->
    </li>
  </ul>
</div>
<div style="display: flex; justify-content: center; width: 100%;">
  <p-button label="Zurück zum Hauptmenü" icon="pi pi-arrow-left" (click)="backToHome()"></p-button>
</div>
