<div class="friend-profile">
  <h2>Profil</h2>

  <div *ngIf="currentFriend">
    <p-stepper #customStepper orientation="horizontal">

      <!-- Schritt: Stammdaten -->
      <p-stepperPanel>
        <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
          <button class="bg-transparent border-none inline-flex flex-column gap-2" (click)="onClick.emit()">
            <i class="fa-solid fa-house-user"></i>
          </button>
        </ng-template>
        <ng-template pTemplate="content" let-nextCallback="nextCallback">
          <div class="flex mb-3 mt-0 gap-2">
            <p-button icon="fa-solid fa-square-poll-vertical" pTooltip="KI Fragebogen" (onClick)="toggle($event)"/>
            <p-button icon="fa-brands fa-creative-commons-by"
                      pTooltip="Personenattribute anzeigen"
                      (onClick)="showPersonenattribute()"/>
            <button
              style="background-color: #f64e69; color: white; border: none; cursor: pointer;"
              (click)="showPersonenbeschreibung()">
              <i class="fa-solid fa-person-circle-question"></i>
            </button>
            <p-button icon="fa-solid fa-comment"
                      pTooltip="Notiz erstellen"
                      (onClick)="showNotiz()"/>
            <p-button icon="fa-solid fa-eye"
                      pTooltip="Notiz anzeigen"
                      (onClick)="showExistingNotiz()"/>
            <p-button icon="fa-solid fa-floppy-disk" (onClick)="submitProfile()" pTooltip="speichern"/>
            <p-button icon="fa-solid fa-circle-right" (onClick)="nextCallback.emit()" pTooltip="weiter"/>
            <p-overlayPanel #overlayP>
              <div class="flex flex-column gap-3 w-35rem">
                <!-- Überschrift -->
                <div>
                  <span class="font-medium text-900 block mb-2">Profilfrage</span>
                </div>

                <!-- KI-generierte Frage -->
                <div>
                  <span class="text-800 block">{{ generatedQuestion }}</span>
                </div>

                <!-- Antwortmöglichkeiten -->
                <div class="mt-3">
                  <!-- Multiple Choice -->
                  <div *ngIf="answerType === 'multipleChoice'">
                    <!-- Multiple-Choice-Checkboxen -->
                    <p-checkbox
                      *ngFor="let option of answerOptions"
                      [label]="option"
                      [value]="option"
                      [(ngModel)]="selectedAnswers"
                      inputId="{{ option }}">
                    </p-checkbox>

                    <!-- Freitextfeld -->
                    <div class="mt-3">
                      <label for="freeText">Andere Antwort:</label>
                      <textarea id="freeText" pInputTextarea [(ngModel)]="freeTextAnswer" rows="1"
                                placeholder="Füge deine eigene Antwort hinzu">
                      </textarea>
                    </div>
                  </div>

                  <!-- Ja/Nein -->
                  <div *ngIf="answerType === 'yesNo'">
                    <p-radioButton label="Ja" [value]="'Ja'" [(ngModel)]="selectedAnswer"></p-radioButton>
                    <p-radioButton label="Nein" [value]="'Nein'" [(ngModel)]="selectedAnswer"></p-radioButton>
                  </div>

                  <!-- Freie Texteingabe -->
                  <div *ngIf="answerType === 'freeText'">
                    <textarea pInputTextarea [(ngModel)]="freeTextAnswer" rows="3"
                              placeholder="Deine Antwort"></textarea>
                  </div>
                </div>

                <!-- Anpassung der Fragestellung -->
                <div class="mt-3">
                  <label for="questionInfluence">Fragestellung anpassen:</label>
                  <textarea
                    id="questionInfluence"
                    pInputTextarea
                    [(ngModel)]="questionInfluence"
                    rows="1"
                    placeholder="Bitte fokussiere die Frage auf...">
                  </textarea>
                </div>

                <!-- Aktionen -->
                <div class="modal-actions mt-4">
                  <p-button label="Abbrechen" icon="pi pi-times" (onClick)="overlayP.hide()"
                            class="p-button-secondary"></p-button>
                  <p-button label="Nächste Frage" icon="pi pi-arrow-right" (onClick)="openQuestionnaire($event)"
                            class="p-button-secondary">
                  </p-button>
                  <p-button
                    label="Antworten"
                    icon="pi pi-check"
                    (onClick)="saveAnswer($event)"
                    class="p-button-primary"
                    [disabled]="isAnswerInvalid()">
                  </p-button>
                </div>
              </div>
            </p-overlayPanel>
          </div>
          <div class="flex-row" style="display: flex; flex-direction: row; gap: 20px; align-items: flex-start;">
            <div>
              <div class="flex-row">
                <label style="width: 100px;" for="friend-name">Vorname</label>
                <input type="text" id="friend-name" name="friend-name" placeholder="Vorname"
                       [(ngModel)]="currentFriend.vorname">
              </div>
              <div class="flex-row">
                <label style="width: 100px;" for="friend-lastname">Nachname</label>
                <input type="text" id="friend-lastname" name="friend-lastname" placeholder="Nachname"
                       [(ngModel)]="currentFriend.nachname">
              </div>
              <div class="flex-row">
                <label style="width: 100px;" for="friend-birthday">Geburtstag</label>
                <input type="date" id="friend-birthday" name="friend-birthday" [(ngModel)]="currentFriend.geburtstag">
              </div>
              <div class="flex-row">
                <label style="width: 100px;" for="friend-geschlecht">Geschlecht</label>
                <select id="friend-geschlecht" name="friend-geschlecht" [(ngModel)]="currentFriend.geschlecht">
                  <option value="" disabled selected>Wähle ein Geschlecht</option>
                  <option value="männlich">Männlich</option>
                  <option value="weiblich">Weiblich</option>
                </select>
              </div>
            </div>
            <div>
              <div class="photo-upload">
                <p-fileUpload *ngIf="!previewImageUrl" mode="basic" chooseIcon="pi pi-upload" accept="image/*"
                              maxFileSize="10000000" (onSelect)="onFileSelected($event)"
                              (onUpload)="onBasicUploadAuto($event)"
                              [auto]="true" [url]="previewImageUrl" chooseLabel="Foto hochladen">
                </p-fileUpload>
                <div id="preview-container">
                  <p-image *ngIf="previewImageUrl" [src]="previewImageUrl" class="preview-container" [preview]="true"/>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="personenattribute">
            <label for="personenattribute">Personenattribute anzeigen:</label>
            <textarea id="personenattribute" style="width: 100%;" [(ngModel)]="personenattribute" rows="10"></textarea>
          </div>
          <div *ngIf="notiz">
            <label for="notiz">Notiz eintragen:</label>
            <textarea id="notiz" style="width: 100%;" [(ngModel)]="notiz" rows="10"></textarea>
            <p-button icon="fa-solid fa-floppy-disk" [style]="{'margin-left': '10px'}" (onClick)="saveNotiz()"
                      pTooltip="Speichern"/>
          </div>
          <div *ngIf="notizen && notizen.length > 0">
            <p-listbox [options]="notizen" optionLabel="displayLabel"/>
          </div>
        </ng-template>
      </p-stepperPanel>

      <!-- Schritt: Persönliche Informationen -->
      <p-stepperPanel>
        <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
          <button class="bg-transparent border-none inline-flex flex-column gap-2" (click)="onClick.emit()">
            <i class="pi pi-user"></i>
          </button>
        </ng-template>
        <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback">
          <div class="flex gap-2 mb-4">
            <p-button icon="fa-solid fa-circle-left" (onClick)="prevCallback.emit()" pTooltip="zurück"/>
            <p-button icon="fa-solid fa-circle-right" (onClick)="nextCallback.emit()" pTooltip="weiter"/>
            <p-button icon="fa-solid fa-floppy-disk" (onClick)="submitProfile()" pTooltip="speichern"/>
          </div>
          <div class="flex-row" style="display: flex; flex-direction: row; gap: 20px; align-items: flex-start;">
            <div>
              <div class="flex-row mb-4 w-100" *ngFor="let attribute of profileAttributes">
                <app-dynamic-form-field
                  [attribute]="attribute"
                  [value]="getAttributeValue(attribute.key)"
                  (valueChange)="setAttributeValue(attribute.key, $event)">
                </app-dynamic-form-field>
              </div>
              <div style="margin-top: 10px;" class="flex-row">
                <label>Interesse</label>
                <p-rating name="interesse" [ngModel]="getAttributeValue('Interesse')" [stars]="10"
                          (ngModelChange)="setAttributeValue('Interesse', $event)"></p-rating>
              </div>
            </div>
          </div>

        </ng-template>
      </p-stepperPanel>

      <!-- Schritt: Familie und Beziehung -->
      <p-stepperPanel>
        <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
          <button class="bg-transparent border-none inline-flex flex-column gap-2" (click)="onClick.emit()">
            <i class="fa-solid fa-people-group"></i>
          </button>
        </ng-template>
        <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback">

          <div class="flex gap-2 mb-4">
            <p-button icon="fa-solid fa-circle-left" (onClick)="prevCallback.emit()" pTooltip="zurück"/>
            <p-button icon="fa-solid fa-circle-right"  (onClick)="nextCallback.emit()" pTooltip="weiter"/>
            <p-button icon="fa-solid fa-floppy-disk" (onClick)="submitProfile()" pTooltip="speichern"/>
          </div>
          <div class="flex-row">
            <label class="w-2">Partner</label>
            <p-autoComplete [suggestions]="filteredPartner"
                            (completeMethod)="filterPartner($event)" [(ngModel)]="partnerId2"
                            (onSelect)="updatePartnerConnection($event)"
                            [field]="'label'" [ngModelOptions]="{ standalone: true }"
                            [dropdown]="true" class="flex-grow">
            </p-autoComplete>
            <button class="p-button p-button-sm p-button-outlined ml-2"
                    (click)="navigateToPerson(partnerId2!)"
                    type="button"
                    [disabled]="!partnerId2">
              <i class="pi pi-arrow-right"></i>
            </button>
          </div>
          <div class="flex-row">
            <label class="w-2" for="kids">Kinder</label>
            <input type="number" id="kids" name="kids" min="0"
                   (ngModelChange)="onKidsNumberChange($event)"
                   [ngModel]="getKidsCount()"/>
          </div>

          <div *ngIf="currentFriend.connection1.length > 0" class="flex-column">
            <ng-container *ngFor="let connection of getKidsConnections(); let i = index">
              <div class="flex align-items-center gap-2">
                <!-- Label -->
                <label class="w-2" [for]="'connection' + i">Name Kind {{ i + 1 }}</label>

                <!-- Input Box mit Autocomplete -->
                <div class="flex align-items-center gap-1 w-full">
                  <p-autoComplete
                    [suggestions]="filteredKidNamesArray[i]"
                    (completeMethod)="filterKidNames($event, i)"
                    [(ngModel)]="connection.id2"
                    [field]="'label'"
                    [ngModelOptions]="{ standalone: true }"
                    [dropdown]="false"
                    class="flex-grow">
                  </p-autoComplete>

                  <button class="p-button p-button-sm p-button-outlined"
                          (click)="navigateToPerson(connection.id2!)"
                          type="button"
                          [disabled]="!connection.id2">
                    <i class="pi pi-arrow-right"></i>
                  </button>
                </div>

              </div>
            </ng-container>
          </div>


        </ng-template>
      </p-stepperPanel>

      <!-- Schritt: Business und Beruf -->
      <p-stepperPanel>
        <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
          <button class="bg-transparent border-none inline-flex flex-column gap-2" (click)="onClick.emit()">
            <i class="fa-solid fa-euro-sign"></i>
          </button>
        </ng-template>
        <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback">
          <div class="flex gap-2 mb-4">
            <p-button icon="fa-solid fa-circle-left" (onClick)="prevCallback.emit()" pTooltip="zurück"/>
            <p-button icon="fa-solid fa-circle-right"  (onClick)="nextCallback.emit()" pTooltip="weiter"/>
            <p-button icon="fa-solid fa-floppy-disk" (onClick)="submitProfile()" pTooltip="speichern"/>
          </div>
          <div class="flex-row mb-4" *ngFor="let attribute of business">
            <app-dynamic-form-field
              [attribute]="attribute"
              [value]="getAttributeValue(attribute.key)"
              (valueChange)="setAttributeValue(attribute.key, $event)">
            </app-dynamic-form-field>
          </div>
        </ng-template>
      </p-stepperPanel>

      <!-- Schritt: Soziale und Freizeitaktivitäten -->
      <p-stepperPanel>
        <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
          <button class="bg-transparent border-none inline-flex flex-column gap-2" (click)="onClick.emit()">
            <i class="fa-solid fa-thumbs-up"></i>
          </button>
        </ng-template>
        <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback">
          <div class="flex gap-2 mb-4">
            <p-button icon="fa-solid fa-circle-left" (onClick)="prevCallback.emit()" pTooltip="zurück"/>
            <p-button icon="fa-solid fa-circle-right"  (onClick)="nextCallback.emit()" pTooltip="weiter"/>
            <p-button icon="fa-solid fa-floppy-disk" (onClick)="submitProfile()" pTooltip="speichern"/>
          </div>
          <div class="flex-row mb-4" *ngFor="let attribute of facts">
            <app-dynamic-form-field
              [attribute]="attribute"
              [value]="getAttributeValue(attribute.key)"
              (valueChange)="setAttributeValue(attribute.key, $event)">
            </app-dynamic-form-field>
          </div>
        </ng-template>
      </p-stepperPanel>

      <!-- Schritt: Hobbys und Freizeitpräferenzen -->
      <p-stepperPanel>
        <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
          <button class="bg-transparent border-none inline-flex flex-column gap-2" (click)="onClick.emit()">
            <i class="fa-solid fa-medal"></i>
          </button>
        </ng-template>
        <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback">
          <div class="flex gap-2 mb-4">
            <p-button icon="fa-solid fa-circle-left" (onClick)="prevCallback.emit()" pTooltip="zurück"/>
            <p-button icon="fa-solid fa-circle-right"  (onClick)="nextCallback.emit()" pTooltip="weiter"/>
            <p-button icon="fa-solid fa-floppy-disk" (onClick)="submitProfile()" pTooltip="speichern"/>
          </div>
          <div class="flex-row mb-4" *ngFor="let attribute of activities">
            <app-dynamic-form-field
              [attribute]="attribute"
              [value]="getAttributeValue(attribute.key)"
              (valueChange)="setAttributeValue(attribute.key, $event)">
            </app-dynamic-form-field>
          </div>
        </ng-template>
      </p-stepperPanel>

      <!-- Schritt: Tier- und Naturvorlieben -->
      <p-stepperPanel>
        <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
          <button class="bg-transparent border-none inline-flex flex-column gap-2" (click)="onClick.emit()">
            <i class="fa-solid fa-shield-dog"></i>
          </button>
        </ng-template>
        <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback">
          <div class="flex gap-2 mb-4">
            <p-button icon="fa-solid fa-circle-left" (onClick)="prevCallback.emit()" pTooltip="zurück"/>
            <p-button icon="fa-solid fa-circle-right"  (onClick)="nextCallback.emit()" pTooltip="weiter"/>
            <p-button icon="fa-solid fa-floppy-disk" (onClick)="submitProfile()" pTooltip="speichern"/>
          </div>
          <div class="flex-row mb-4" *ngFor="let attribute of tiere">
            <app-dynamic-form-field
              [attribute]="attribute"
              [value]="getAttributeValue(attribute.key)"
              (valueChange)="setAttributeValue(attribute.key, $event)">
            </app-dynamic-form-field>
          </div>
        </ng-template>
      </p-stepperPanel>

      <!-- Schritt: Essens- und Trinkgewohnheiten -->
      <p-stepperPanel>
        <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
          <button class="bg-transparent border-none inline-flex flex-column gap-2" (click)="onClick.emit()">
            <i class="fa-solid fa-utensils"></i>
          </button>
        </ng-template>
        <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback">
          <div class="flex gap-2 mb-4">
            <p-button icon="fa-solid fa-circle-left" (onClick)="prevCallback.emit()" pTooltip="zurück"/>
            <p-button icon="fa-solid fa-circle-right"  (onClick)="nextCallback.emit()" pTooltip="weiter"/>
            <p-button icon="fa-solid fa-floppy-disk" (onClick)="submitProfile()" pTooltip="speichern"/>
          </div>
          <div class="flex-row mb-4" *ngFor="let attribute of foodAndDrinks">
            <app-dynamic-form-field
              [attribute]="attribute"
              [value]="getAttributeValue(attribute.key)"
              (valueChange)="setAttributeValue(attribute.key, $event)">
            </app-dynamic-form-field>
          </div>
        </ng-template>
      </p-stepperPanel>

      <!-- Schritt: Foto hochladen -->
      <p-stepperPanel>
        <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
          <button class="bg-transparent border-none inline-flex flex-column gap-2" (click)="onClick.emit()">
            <i class="fa-solid fa-camera"></i>
          </button>
        </ng-template>
        <ng-template pTemplate="content" let-prevCallback="prevCallback">
          <div class="flex gap-2 mb-4">
            <p-button icon="fa-solid fa-circle-left" (onClick)="prevCallback.emit()" pTooltip="zurück"/>
            <p-button icon="fa-solid fa-floppy-disk" (onClick)="submitProfile()" pTooltip="speichern"/>
          </div>
          <div class="photo-upload">
            <p-fileUpload mode="basic" chooseIcon="pi pi-upload" accept="image/*"
                          maxFileSize="10000000"
                          (onSelect)="onFileSelected($event)"
                          (onUpload)="onBasicUploadAuto($event)"
                          [auto]="true"
                          [url]="previewImageUrl"
                          chooseLabel="Foto hochladen">
            </p-fileUpload>
            <div>
              <img *ngIf="previewImageUrl" [src]="previewImageUrl" alt="Hochgeladenes Bild">
            </div>
          </div>
        </ng-template>
      </p-stepperPanel>

    </p-stepper>
  </div>
</div>

