import {Component, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrl: 'sidebar.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {
}
