import { Component, Input, OnInit } from '@angular/core';
import { Freunde } from '../../model/freunde';
import {Router} from "@angular/router";

@Component({
  selector: 'app-dart-result',
  templateUrl: './dart-result.component.html',
  styleUrls: ['./dart-result.component.scss']
})
export class DartResultComponent implements OnInit {
  @Input() players: Freunde[] = [];  // Die Spieler mit ihren Daten
  @Input() playerScores: number[] = [];  // Die Punktzahlen der Spieler
  maxScore: number = 0;

  winner!: Freunde;  // Gewinner des Spiels

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.calculateWinner();  // Bestimme den Gewinner, wenn die Komponente geladen wird
  }
  // Methode zur Bestimmung des Spielgewinners und Sortierung der Spieler
  calculateWinner(): void {
    this.maxScore = Math.max(...this.playerScores);
    const playersWithScores = this.players.map((player, index) => ({
      player: player,
      score: this.playerScores[index]
    }));

    // Sortiere die Spieler basierend auf den Punktzahlen in absteigender Reihenfolge
    playersWithScores.sort((a, b) => b.score - a.score);

    // Aktualisiere die Spieler- und Punktelisten basierend auf der Sortierung
    this.players = playersWithScores.map(item => item.player);
    this.playerScores = playersWithScores.map(item => item.score);

    // Setze den Gewinner (Spieler mit dem höchsten Score)
    this.winner = this.players[0];  // Da das Array absteigend sortiert ist, ist der erste Spieler der Gewinner
  }


  isWinner(index: number): boolean {
    const maxScore = Math.max(...this.playerScores);
    return this.playerScores[index] === maxScore;
  }

  startNewGame(): void {
    window.location.reload();
  }
}
