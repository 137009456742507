.selected-game-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 2rem auto;
  padding: 1.5rem;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  h3, h4 { text-align: center; color: #333; }
  .game-summary, .game-details { width: 100%; margin-bottom: 1rem; }
  p { color: #555; margin: 0.5rem 0; }

  .wurf-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;

    thead {
      background: #007bff;
      color: #fff;
      th { padding: 0.75rem; font-size: 1rem; font-weight: 600; }
    }

    tbody tr {
      background: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: transform 0.2s;

      &:hover { transform: scale(1.02); }
      &:nth-child(even) { background: #dc1616; } // Abwechselnde Zeilenfarbe

      td {
        padding: 0.75rem;
        text-align: center;

        &:first-child {
          font-weight: 600;
          color: #007bff;
          background: #e6f2ff;
          border-radius: 8px 0 0 8px;
        }
        &:last-child { background: #f2f2f2; border-radius: 0 8px 8px 0; }
      }
    }
  }
}

p-button button {
  background: #007bff;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover { background: #0056b3; }
  i { margin-right: 0.5rem; }
}
