import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EventsService} from "../../service/events.service";

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrl: './add-event.component.scss'
})
export class AddEventComponent {

  showEndDateField = false;
  @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Input() startDate: Date = new Date();
  endDate: Date | null = null;
  selectedItems: string[] = [];
  items: string[] = ['Apple', 'Banana', 'Orange', 'Grapes', 'Pineapple'];
  selectedPhoto: any = null; // Variable zum Speichern des ausgewählten Fotos
  selectedCategory: string = 'work';
  description: any;
  photos = [
    {src: 'assets/igor_s.png', id: 'Igor'},
    {src: 'assets/TabeaZerr.png', id: 'Tabea'},
    {src: 'assets/RubyZerr.png', id: 'Ruby'},
    {src: 'assets/LucyZerr.png', id: 'Lucy'},
    {src: 'assets/HenryZerr.png', id: 'Henry'}
  ];
  categories = [
    {label: 'Arbeit', value: 'work'},
    {label: 'Privat', value: 'privat'},
    {label: 'Soziales', value: 'soziales'},
    {label: 'Kinder', value: 'kinder'},
    {label: 'Hausbau', value: 'hausbau'},
    {label: 'Sport', value: 'sport'},
    {label: 'Urlaub', value: 'urlaub'}
  ];

  constructor(private eventsService: EventsService) {
  }

  selectPhoto(photo: any) {
    this.selectedPhoto = photo; // Speichert das ausgewählte Foto
  }

  submit() {
    const eventDetails = {
      id: null,
      startdate: this.startDate.toISOString(),
      enddate: this.endDate ? this.endDate.toISOString() : '',
      kategorie: this.selectedCategory,
      beschreibung: this.description,
      associatedId: '',
    };
    this.eventsService.addEvent(eventDetails).subscribe({
      next: (response) => {
        this.reset();
        this.closeEvent.emit()
      }
    });
  }

  private reset() {
    this.description = "";
    this.selectedPhoto = {};
  }

  back() {
    this.closeEvent.emit();
  }
}
