<div class="item-list">
  <h3>{{ title }}</h3>
  <div class="search-bar">
    <input type="text" [(ngModel)]="searchQuery" (input)="onSearch()" placeholder="Suche Artikel..."/>
    <button (click)="onAddItem()">+</button>
  </div>
  <ul>
    <table>
      <tbody>
      <tr *ngFor="let item of filteredItems" (dblclick)="editArtikel(item)">
        <td>
<!--          <p-image [src]="item.photo" alt="{{ item.name }}" [preview]="true" class="custom-p-image"></p-image>-->
          <img [src]="item.photo" alt="{{ item.name }}" class="custom-p-image" />
        </td>
        <td style="font-size: 16px;"><p
          style="margin-left: 10px;margin-right: 10px; user-select: none;">{{ item.name }}</p></td>
        <td><strong style="font-size: 12px;margin-left: 16px;">Lagerraum:</strong> {{ item.ort }}</td>
        <td><strong style="font-size: 12px;margin-left: 16px;">Beschreibung:</strong>{{ item.beschreibung }}</td>
      </tr>
      </tbody>
    </table>
  </ul>
</div>
