<div class="technik-inventar-form">
  <h2 style="align-content: center;">Technik-Inventar</h2>
  <form (ngSubmit)="onSubmit()" class="form-layout">
    <!-- Foto und Vorschau -->
    <div class="photo-section">
      <label>Foto</label>
      <div class="photo-upload">
        <p-fileUpload *ngIf="!previewImageUrl" mode="basic" chooseIcon="pi pi-upload" accept="image/*"
                      maxFileSize="10000000" (onSelect)="onFileSelected($event)" (onUpload)="onBasicUploadAuto($event)"
                      [auto]="true" [url]="previewImageUrl" chooseLabel="Foto hochladen">
        </p-fileUpload>
        <div id="preview-container">
          <p-image *ngIf="previewImageUrl" [src]="previewImageUrl" alt="Image" width="250" [preview]="true"/>
        </div>
      </div>
    </div>

    <!-- Formularfelder -->
    <div class="form-fields">
      <!-- Name -->
      <div class="form-group">
        <label for="name">Name</label>
        <input type="text" id="name" name="name" class="form-control" [(ngModel)]="technik.name" required/>
      </div>

      <!-- Beschreibung -->
      <div class="form-group">
        <label for="beschreibung">Beschreibung</label>
        <textarea id="beschreibung" name="beschreibung" class="form-control" [(ngModel)]="technik.beschreibung"></textarea>
      </div>

      <!-- Kategorie -->
      <div class="form-group">
        <label for="kategorie">Kategorie</label>
        <select id="kategorie" name="kategorie" class="form-control" [(ngModel)]="technik.kategorie" required>
          <option value="Laptop">Laptop</option>
          <option value="Smartphone">Smartphone</option>
          <option value="Tablet">Tablet</option>
          <option value="Sonstiges">Sonstiges</option>
        </select>
      </div>

      <!-- Ort -->
      <div class="form-group">
        <label for="ort">Ort</label>
        <input type="text" id="ort" name="ort" class="form-control" [(ngModel)]="technik.ort" required/>
      </div>

      <!-- Menge -->
      <div class="form-group">
        <label for="menge">Menge</label>
        <input type="number" id="menge" name="menge" class="form-control" [(ngModel)]="technik.menge" required min="1"/>
      </div>

      <!-- Buttons -->
      <div class="form-buttons">
        <button type="submit" class="btn btn-primary">{{ submitButton }}</button>
        <button type="button" class="btn btn-secondary" (click)="onBack()">Zurück</button>
      </div>
    </div>
  </form>
</div>
