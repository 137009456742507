import {Attribute} from "./attribute";
import {Connection} from "./connection";

export interface Freunde {
  id: string | null,
  vorname: string,
  nachname: string,
  fullname: string,
  geburtstag: string,
  photo: string,
  geschlecht: string,
  attributes: Attribute[];
  connection1: Connection[];
  connection2: Connection[];
  connections: Connection[];
}

export function friendToString(freund: Freunde): string {
  if (!freund) {
    return 'Ungültiger Freund';
  }

  const attributes = freund.attributes?.map(attr => `${attr.attribut}: ${attr.value}`).join('\n') || 'Keine Attribute';

  return `Vorname: ${freund.vorname || 'Unbekannt'}
          Nachname: ${freund.nachname || 'Unbekannt'}
          Vollständiger Name: ${freund.fullname || 'Unbekannt'}
          Geburtstag: ${freund.geburtstag || 'Nicht angegeben'}
          Attribute: ${attributes}`;
}
