import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Events} from "../model/events";
import {EventsService} from "../service/events.service";
import {FreundeService} from "../service/freunde.service";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrl: './timeline.component.scss',
})
export class TimelineComponent implements OnInit {
  events: Events[] = [];

  constructor(private eventsService: EventsService,
              private friendService: FreundeService) {
  }

  imageMap: { [key: string]: string } = {
    "G13 Haze": "/assets/g13.png",
    "Pineapple Chunk": "/assets/pineapple.png",
    "Zkittles OG Auto": "/assets/zkittles.png",
    "Glue Gelato Auto": "/assets/gluegelato.png",
    "Strawberry Cheesecake Auto": "/assets/strawberry.png",
    "Sour Diesel Auto": "/assets/sourDiesel.png",
    "Moby Dick Auto*": "/assets/mobydick.png",
    "Amnesia Lemon*": "/assets/amnesiaLemon.png",
    "Laughing Buddha Auto*": "/assets/laughingBuddha.png",
    "Mimosa X Orange Punch*": "/assets/mimosa.png",
    "Tangering Dream Auto": "/assets/tangerine.png",
    "Skywalker OG Auto": "/assets/skywalker.png",
  };

  ngOnInit() {
    this.eventsService.getEvents().subscribe(response => {
      this.events = response.map(event => {

        if (event.associatedId && event.associatedId.length > 0) this.checkForFriendPhoto(event);
        if (event.kategorie === 'Menu') this.checkForMenu(event);
        if (event.kategorie === 'ToDo') this.setCheckmark(event);
        return event;
      });
    });
  }

  private checkForFriendPhoto(event: Events) {
    this.friendService.getById(event.associatedId).subscribe(friend => {
      if (friend) {
        event.associatedId = `${environment.downloadUrl}thumbnails/${friend.photo}`;
      } else {
        event.associatedId = ''; // Setze associatedId auf leer, falls Freund nicht gefunden
      }
    });
  }

  private checkForMenu(event: Events) {
    const sorteMatch = event.beschreibung.match(/Sorte:\s*(.*)/); // Extrahiere den Namen der Sorte
    if (sorteMatch && sorteMatch[1]) {
      const sorteName = sorteMatch[1].trim(); // Entferne Leerzeichen um den extrahierten Namen
      if (this.imageMap[sorteName]) {
        event.associatedId = this.imageMap[sorteName]; // Setze den entsprechenden Value aus imageMap
      }
    }
  }

  private setCheckmark(event: Events) {
    event.associatedId = 'data:image/svg+xml;base64,' + btoa(`
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="green" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M5 12l5 5L20 7"></path>
            </svg>`);
  }
}
