import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DartsService} from "../../service/darts.service";
import {FreundeService} from "../../service/freunde.service";
import {Freunde} from "../../model/freunde";
import {Dart} from "../../model/darts";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-dart-ranking',
  templateUrl: './dart-ranking.component.html',
  styleUrls: ['./dart-ranking.component.scss']
})
export class DartRankingComponent implements OnInit {
  rankedDartGames: Array<{
    id: string;
    points: number;
    player: Freunde | { fullname: string; photo: string };
    createdDate: string | null;
  }> = [];
  friendsList: Freunde[] = [];
  @Output() backToMain = new EventEmitter<unknown>();
  @Output() showGameDetails = new EventEmitter<Dart>();

  constructor(private dartsService: DartsService, private friendService: FreundeService) {
  }

  ngOnInit(): void {
    // Lade die Freundesliste
    this.friendService.getAlle().subscribe(friends => {

      this.friendsList = friends.map(friend => {
        return {
          ...friend,
          photo: `${environment.downloadUrl}${friend.photo}`
        };
      });

      // Lade die Dart-Spiele
      this.dartsService.getRankedHighScore().subscribe(dartGames => {
        dartGames.forEach(dartGame => {
          // Spieler IDs extrahieren
          const players = [
            {id: dartGame.playerId1},
            {id: dartGame.playerId2},
            {id: dartGame.playerId3},
            {id: dartGame.playerId4}
          ];

          // Filtere nicht teilnehmende Spieler aus und berechne die Punkte
          players.forEach(player => {
            if (player.id) {
              // Berechne die Punkte für den jeweiligen Spieler basierend auf seinen Würfen
              const playerPoints = this.calculatePlayerPoints(dartGame, player.id);

              // Finde Freundesinformationen
              const friend = this.friendsList.find(f => f.id === player.id);
              const playerInfo = friend ? friend : {fullname: 'Unbekannter Spieler', photo: ''};

              // Füge den Spieler mit allen Informationen zur Rangliste hinzu
              this.rankedDartGames.push({
                id: dartGame.id,
                points: playerPoints,
                player: playerInfo,
                createdDate: dartGame.createdDate
              });
            }
          });
        });

        // Sortiere die Rangliste nach Punkten in absteigender Reihenfolge
        this.rankedDartGames.sort((a, b) => b.points - a.points);
      });
    });
  }

  private calculatePlayerPoints(dartGame: Dart, playerId: string): number {
    // Filtere die Würfe für den Spieler und summiere deren Punkte
    return dartGame.wuerfe
      .filter(throwData => throwData.playerId === playerId)
      .reduce((sum, throwData) => sum + throwData.throwValue, 0);
  }

  backToHome() {
    this.backToMain.emit();
  }

  loadPlayedGame(index: number, $event: MouseEvent) {
    $event.stopPropagation();
    this.dartsService.getDartGame(this.rankedDartGames[index].id).subscribe(
      response =>{
        this.showGameDetails.emit(response);
    })
  }
}
