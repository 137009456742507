<div class="add-event-container">
  <div class="add-event-form">
    <!-- Ersteller Auswahl -->
    <div class="field">
      <div class="creator-icons">
        <img *ngFor="let photo of photos" [src]="photo.src" [alt]="photo.src"
             (click)="selectPhoto(photo)"
             [ngClass]="{'selected': selectedPhoto === photo}"
             width="32" height="32">
      </div>
    </div>


    <!-- Datum Auswahl -->
    <div class="field">
      <p-calendar [(ngModel)]="startDate" dateFormat="dd.mm.yy" [showButtonBar]="true"
                  [showTime]="true" hourFormat="24" class="w-100"/>
      <p-checkbox [(ngModel)]="showEndDateField" [binary]="true" inputId="binary" label="Endedatum"/>
      <div *ngIf="showEndDateField">
        <p-calendar [(ngModel)]="endDate" dateFormat="dd.mm.yy" [showTime]="true" hourFormat="24" class="w-100"/>
      </div>
    </div>

    <div class="field" id="timeField" style="display: none;">
      <label for="startTime">Startzeit</label>
      <input type="time" id="startTime" name="startTime">
      <label for="endTime">Endzeit</label>
      <input type="time" id="endTime" name="endTime">
    </div>

    <!-- Kategorie Auswahl -->
    <div class="field">
      <label>Kategorie</label>
      <p-selectButton [(ngModel)]="selectedCategory" [options]="categories"></p-selectButton>
    </div>

    <!-- Beschreibung -->
    <div class="field">
      <label>Beschreibung</label>
      <textarea pInputText [(ngModel)]="description" rows="3"></textarea>
    </div>

    <!-- Teilnehmer Auswahl -->
    <div class="field">
      <label>Teilnehmer</label>
    </div>

    <div class="flex-row align-content-center">
      <p-button (onClick)="submit()" [ngStyle]="{ 'margin-right': '8px' }">Event hinzufügen</p-button>
      <p-button (onClick)="back()">Abbrechen</p-button>
    </div>
  </div>
</div>
