// auth.guard.ts
import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {AuthService} from "../service/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const allowedRoles = route.data['role'] as string[];
    const userRole = this.authService.getRole();

    if (allowedRoles.includes(userRole)) {
      return true;
    } else {
      this.router.navigate(['/home']); // Leitet zu /home um, wenn die Rolle nicht passt
      return false;
    }
  }
}
