
export interface Menu {
  id: string | null,
  sorte: string;
  count: number;
  timestamp: string;
  gramm: number;
  geschmack: string;
  wirkung: string;
  type: string;
  sativa: number;
  indica: number;
}

// Hilfsfunktion für toString
export function menuToString(menu: Menu): string {
  return `Diese Sorte ${menu.sorte} liegt vor. `;
}
