import {Component, OnInit} from '@angular/core';
import {Freunde} from "../../model/freunde";
import {FreundeService} from "../../service/freunde.service";
import {DartsService} from "../../service/darts.service";
import {Dart} from "../../model/darts";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-darts',
  templateUrl: './darts.component.html',
  styleUrl: './darts.component.scss',
  providers: [DatePipe]
})
export class DartsComponent implements OnInit {
  continueDarts: Dart | undefined;
  players: Freunde[] = [];
  selectedPlayerCount!: number; // Anzahl der Spieler
  selectedPlayers: Freunde[] = [];
  newDartGameId: string = '';
  showResult: boolean = false;
  playerScores: number[] = [];
  showStartGameScreen = true;
  showRankingList = false;
  showGameDetails = false;
  gameOptions = [
    {label: 'Around the Clock', value: 'game1', image: 'assets/dart-around-the-clock.png'},
    {label: '301', value: 'game2', image: 'assets/dart-301.png'},
    {label: '501', value: 'game3', image: 'assets/dart-501.png'},
    {label: 'Shanghai', value: 'game4', image: 'assets/dart-shanghai.jpeg'}
  ];
  playerCountOptions = [
    {label: '1 Spieler', value: 1},
    {label: '2 Spieler', value: 2},
    {label: '3 Spieler', value: 3},
    {label: '4 Spieler', value: 4}
  ];
  value: number = 501;
  selectedGameTyp: string | null = '';
  selectedGameForDetails: Dart | undefined;

  constructor(private freundeService: FreundeService,
              private dartsService: DartsService,
              private datePipe: DatePipe) {
  }

  isDisabled(index: number): boolean {
    return index >= 1 && index <= 3; // Bild 2-4 deaktivieren
  }

  ngOnInit() {
    this.continueDarts = {
      id: '',
      playerId1: null,
      playerId2: null,
      playerId3: null,
      playerId4: null,
      gameTyp: null,
      points: 0,
      createdDate: null,
      wuerfe: [],
    };
    this.getFreundeList();
    this.dartsService.gameToBeContinuedExists().subscribe(
      (response: Dart) => {
        this.continueDarts = response;
      }
    );

  }

  selectGame(game: any) {
    this.selectedGameTyp = game.label;
    this.startGame();
  }

  getFreundeList() {
    this.freundeService.getAlle().subscribe(
      response => {
        this.players = response;
      })
  }

  getFreundById(id: string): Freunde | undefined {
    return this.players.find(f => f.id === id);
  }

  startGame(): void {
    this.showStartGameScreen = false;

    if (this.selectedPlayerCount && this.selectedPlayers.length === this.selectedPlayerCount && this.selectedGameTyp) {
      // Erstelle ein neues Dart-Spiel-Objekt mit den ausgewählten Spielern und der Anzahl
      const newDartGame: Dart = {
        id: '', // Das Backend generiert eine UUID
        playerId1: this.selectedPlayers[0]?.id,
        playerId2: this.selectedPlayers[1]?.id,
        playerId3: this.selectedPlayers[2]?.id,
        playerId4: this.selectedPlayers[3]?.id,
        gameTyp: this.selectedGameTyp,
        points: 0,
        createdDate: '',
        wuerfe: [] // Anfangs leeres Array für die Würfe
      };

      // Dart-Spiel über den Service hinzufügen
      this.dartsService.addDartGame(newDartGame).subscribe(
        (response: string) => {
          this.newDartGameId = response;
        }
      );
    } else {
      console.error('Bitte wähle die Anzahl der Spieler, Spieler und das Spiel aus');
    }
  }

  getFilteredPlayers(currentIndex: number): Freunde[] {
    return this.players.filter(player =>
      !this.selectedPlayers.some((selectedPlayer, index) =>
        selectedPlayer?.id === player.id && index !== currentIndex
      )
    );
  }


  showResultScreen(scores: number[]): void {
    this.playerScores = scores; // Save the player scores
    this.showResult = true;
    this.newDartGameId = '';
  }

  onBackToMain() {
    this.newDartGameId = ''; // Setze auf leer, um das Hauptpanel wieder einzublenden
    this.showStartGameScreen = true;
    this.showRankingList = false;
    this.showGameDetails = false;
  }

  startFixedGameIgorScreen() {
    this.selectedGameTyp = "Around the Clock"
    this.selectedPlayers = this.players.filter(player => player.fullname === 'Igor Zerr');
    this.selectedPlayerCount = 1
    this.startGame()
  }

  openRankingScreen() {
    this.showStartGameScreen = false;
    this.showRankingList = true;
  }

  showPreviousGameDetailsScreen(selectedGame: Dart) {
    this.selectedGameForDetails = selectedGame;
    this.showStartGameScreen = false;
    this.showRankingList = false;
    this.showGameDetails = true;
  }


  continueLastGame() {
    if (this.continueDarts) {
      this.showStartGameScreen = false;
      this.newDartGameId = this.continueDarts.id;
      this.selectedGameTyp = this.continueDarts.gameTyp;
      this.selectedPlayers = [this.continueDarts.playerId1, this.continueDarts.playerId2, this.continueDarts.playerId3, this.continueDarts.playerId4]
        .filter((id): id is string => id !== null)
        .map(id => this.getFreundById(id))
        .filter((player): player is Freunde => player !== undefined);
      this.selectedPlayerCount = this.selectedPlayers.length;
    }
  }


  getFormattedDate(): string | undefined {
    return this.continueDarts?.createdDate ? this.datePipe.transform(this.continueDarts.createdDate, 'dd.MM.yyyy HH:mm') || undefined : undefined;
  }

}
