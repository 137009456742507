
.friend-profile {
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  padding: 0.2rem;
  background-color: rgba(247, 247, 247, 0.19);
  border-radius: 8px;
  box-shadow: 0px 3px 2px 1px rgb(248, 248, 248);

  h2 {
    text-align: center;
    font-size: 1.5rem;
    color: #ffffff;
    margin-bottom: 1.5rem;
  }

  label {
    font-size: 1.0rem;
    color: #aaa7a7;
    margin-right: 10px;
  }

  .profile-form {
    display: flex;
    flex-direction: column;

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="date"],
    input[type="file"] {
      font-size: 0.7rem;
      padding: 0.5rem;
      margin-bottom: 1rem;
      border: 1px solid #ccc;
      border-radius: 4px;
      transition: border-color 0.2s ease;

      &:focus {
        border-color: #0066cc;
        outline: none;
      }
    }

    .photo-preview {
      margin-bottom: 1rem;
      text-align: center;

      p {
        font-size: 1rem;
        color: #666;
        margin-bottom: 0.5rem;
      }

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 8px;
        border: 1px solid #ccc;
      }
    }

    button {
      font-size: 1rem;
      padding: 0.75rem;
      background-color: #0066cc;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #004999;
      }
    }
  }
}

.photo-upload {
  display: flex;
  flex-direction: column;
  align-items: center;

  .upload-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  #file-input {
    display: none; // Versteckt den eigentlichen Datei-Input
  }
}

.p-stepper .p-stepper-header {
  padding: 0rem;
}

.preview-container img{
  height: 400px;
}

@media (max-width: 576px) {
  .preview-container img{
    height: 250px;
  }
}
