import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {EventsService} from "../service/events.service";
import {Events} from "../model/events";
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-kalender',
  templateUrl: './kalender.component.html',
  styleUrl: './kalender.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class KalenderComponent implements OnInit {
  date: Date = new Date(); // Aktuelles Datum
  events: Array<Events> = [];
  showAddEvent: boolean = false;
  showCalender: boolean = true;
  numberOfMonths: number = 4;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private eventsService: EventsService) {
  }

  ngOnInit() {
    this.setNumberOfMonths();

    this.eventsService.getEvents().subscribe(
      response => {
        this.events = response;
      });
  }

  setNumberOfMonths() {
    if (isPlatformBrowser(this.platformId)) {
      this.numberOfMonths = window.innerWidth <= 768 ? 1 : 4;
    } else {
      // Fallback-Wert für den Server
      this.numberOfMonths = 1;
    }
  }

  addEvent(date: Date) {
  }

  removeEvent(date: Date) {
  }

  hasEventForDate(date: { day: number, month: number, year: number }) {
    let hasEvent = this.events.some(event => {
      const eventDate = new Date(event.startdate);
      return (
        eventDate.getDate() === date.day &&
        eventDate.getMonth() === date.month - 1 &&
        eventDate.getFullYear() === date.year
      );
    });
    return hasEvent;
  }


  getEventsForDate(date: Date) {
    return this.events.filter(event => {
      const eventDate = new Date(event.startdate);
      return (
        eventDate.getDate() === date.getDate() &&
        eventDate.getMonth() === date.getMonth() &&
        eventDate.getFullYear() === date.getFullYear()
      );
    });
  }


  isToday(date: { day: number, month: number, year: number }) {
    let today = new Date(); // Aktuelles Datum

    return today.getFullYear() === date.year &&
      today.getMonth() === date.month &&
      today.getDate() === date.day;
  }


  onButtonClick(event: MouseEvent) {
    this.showAddEvent = true;
    this.showCalender = false;
  }

  onRightClick(event: MouseEvent, date: any) {
    this.showAddEvent = true;
    this.showCalender = false;
    this.date = new Date(date.year, date.month, date.day);
  }

  closeOverlay() {
    this.showAddEvent = false;
    this.showCalender = true;
  }
}
