import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Freunde} from "../model/freunde";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  apiUrl = `${environment.apiUrl}/api/upload/picture`;

  constructor(private http: HttpClient) {}

  uploadPhoto(file: File): Observable<{ url: string }> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<{ url: string }>(this.apiUrl, formData);
  }
}
