import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Events} from "../model/events";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ChatGptService {
  baseUrl = `${environment.apiUrl}/api/chatgpt`;

  constructor(private http: HttpClient) { }

  sendMessage(message: string): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/send`, message, {
      headers: { 'Content-Type': 'application/json' },
      responseType: 'text' as 'json' // Erzwingt, dass die Antwort als Text behandelt wird
    });
  }

  profileQuestion(message: string): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/send`, message, {
      headers: { 'Content-Type': 'application/json' },
      responseType: 'text' as 'json' // Erzwingt, dass die Antwort als Text behandelt wird
    });
  }

}
